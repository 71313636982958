import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { AddTokenInterceptor } from './add-token.interceptor';



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        CommonComponentsModule
    ],
    exports: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddTokenInterceptor,
            multi: true
        }
    ]
})
export class ApiModule { }
