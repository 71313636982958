import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiModule } from './api.module';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: ApiModule
})
export class ApiService {

    isAuthenticated: boolean = false;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json'
        })
    };

    constructor(private http: HttpClient) {
        let token = localStorage.getItem('api-token');

        if (token !== null) {
            this.isAuthenticated = true;
        }
    }

    private checkAuth(authenticated: boolean) {
        if (!authenticated) {
            return true;
        }

        if (!this.isAuthenticated) {
            return false;
        }

        return true;
    }

    put<T>(resource: string, body: object, authenticated: boolean = true) {
        if (!this.checkAuth(authenticated)) {
            return null;
        }
        return this.http.put<T>(environment.apiBaseURL + resource, body, this.httpOptions);
    }

    post<T>(resource: string, body: object, authenticated: boolean = true) {
        if (!this.checkAuth(authenticated)) {
            return null;
        }
        return this.http.post<T>(environment.apiBaseURL + resource, body, this.httpOptions);
    }

    get<T>(resource: string, authenticated: boolean = true) {
        if (!this.checkAuth(authenticated)) {
            return null;
        }
        return this.http.get<T>(environment.apiBaseURL + resource, this.httpOptions);
    }

    delete<T>(resource: string) {
        if (!this.checkAuth(true)) {
            return null;
        }
        return this.http.delete<T>(environment.apiBaseURL + resource, this.httpOptions);
    }
}
