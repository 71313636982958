import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as csv from 'csvtojson';
import { Person } from 'src/app/api/models/person.model';
import { PeopleService } from 'src/app/api/people.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FailedPerson } from 'src/app/api/interfaces/addpeoplereturn';

@Component({
    selector: 'app-people-uploader',
    templateUrl: './people-uploader.component.html'
})
export class PeopleUploaderComponent implements OnInit {

    csvFile: any;
    csvData: object[] =  [];
    csvFields: {name: string, display: string}[] = [];
    firstRowHeaders = true;
    sendWelcomeEmail = false;

    firstNameField = '';
    lastNameField = '';
    displayNameField = 'first-initial-last-name';
    emailField = '';
    phoneField = '';

    uploadComplete = false;
    uploadedPeople = 0;
    skippedDuplicates = 0;
    failedPeople: FailedPerson[] = [];
    errors = '';
    showErrors = false;

    @Output() finishedUploading = new EventEmitter<boolean>();

    constructor(private peopleService: PeopleService, public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    readCSV(event) {
        this.csvFile = event.target.files[0];
        this.updateCSVData();
    }

    updateCSVData() {
        const reader = new FileReader();
        reader.readAsText(this.csvFile);
        reader.onload = () => {
            csv({noheader: !this.firstRowHeaders}).fromString(reader.result as string).then( json => {
                this.csvData = json;
                if (this.firstRowHeaders) {
                    this.csvFields = Object.keys(json[0]).map(key => { return {name: key, display: key} });
                } else {
                    this.csvFields = Object.keys(json[0]).map((key, index) => {
                        return {name: key, display: 'Column ' + (index + 1) + ' (' + json[0][key] + ')'}
                    });
                }
                this.firstNameField = this.csvFields[0].name;
                this.lastNameField = this.csvFields[0].name;
                this.emailField = '';
                this.phoneField = '';
            });
        };
    }

    uploadPeople() {
        const people: Person[] = this.csvData.map(element => {
            const person: Person = {
                first_name: element[this.firstNameField],
                last_name: element[this.lastNameField],
                display_name: '',
                email: null,
                phone: null
            };

            if (this.displayNameField === 'first-initial-last-name') {
                person.display_name = person.first_name[0] + ' ' + person.last_name;
            } else if (this.displayNameField === 'first-name-last-name') {
                person.display_name = person.first_name + ' ' + person.last_name;
            } else {
                person.display_name = element[this.displayNameField];
            }

            if (this.emailField !== '') {
                person.email = element[this.emailField];
            }

            if (this.phoneField !== '') {
                person.phone = element[this.phoneField];
            }

            return person;
        });

        this.peopleService.addPeople(people, this.sendWelcomeEmail)
            .subscribe(
                result => {
                    this.uploadComplete = true;
                    this.uploadedPeople = result.people.length;
                    this.skippedDuplicates = result.duplicatePeople.length;
                    this.failedPeople = result.failedPeople;
                }
            );
    }

    done() {
        this.finishedUploading.emit(true);
    }

    viewErrors() {
        if (this.showErrors) {
            this.showErrors = false;
        } else {
            this.showErrors = true;
            this.errors = this.failedPeople.map(p =>
                p.person.first_name + ' ' + p.person.last_name + ': ' + Object.keys(p.errors).map(key => key + ': ' + p.errors[key].join(', ')).join('; ')
            ).join('\n');
        }
    }

}
