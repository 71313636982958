import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from '../confirmation.service';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

    constructor(public service: ConfirmationService) { }

    ngOnInit(): void {
    }

    confirmed(answer: boolean) {
        this.service.visible = false;
        this.service.confirmed(answer);
    }

    cancelled() {
        this.service.visible = false;
        this.service.cancelled();
    }

    clickedBackground(event) {
        if (event.target !== event.currentTarget) return;
        this.cancelled();
      }

}
