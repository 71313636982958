import { Component, OnInit } from '@angular/core';
import { EventTemplatesService } from 'src/app/api/event-templates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventTemplate, EventTemplateTeam } from 'src/app/api/models/eventtemplate.model';
import { Role } from 'src/app/api/models/role.model';
import { ToastService } from 'src/app/common-components/toast.service';
import { Team } from 'src/app/api/models/team.model';
import { NgbPopover, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Event } from 'src/app/api/models/event.model';
import * as moment from 'moment';
import { ConfirmationService } from 'src/app/common-components/confirmation.service';
import { EventsService } from 'src/app/api/events.service';
import { AuthService } from 'src/app/api/auth.service';

@Component({
    selector: 'app-event-template-info',
    templateUrl: './event-template-info.component.html'
})
export class EventTemplateInfoComponent implements OnInit {

    template: EventTemplate;
    popover: NgbPopover;
    selectedEvent: Event;

    createUntil = {year: 0, month: 0, day: 0};

    editingDefaultNotes = '';
    editingDefaultNotesTeamID?: string;

    constructor(
        private route: ActivatedRoute,
        private service: EventTemplatesService,
        private toastService: ToastService,
        private confirmationService: ConfirmationService,
        private routerService: Router,
        private modalService: NgbModal,
        private eventsService: EventsService,
        public authService: AuthService
    ) {
        this.route.params.subscribe(
            params => {
                this.service.getTemplate(params['id'])
                    .subscribe(
                        data => {
                            this.template = Object.assign(new EventTemplate(), data);
                        }
                    );
            }
        );
    }

    ngOnInit(): void {
        const date = moment().add(4, 'weeks');
        this.createUntil = {year: date.year(), month: date.month()+1, day: date.date()};
    }

    deleteTemplate() {
        this.confirmationService.confirm(
            'delete ' + this.template.name + '. This will delete all future instances of this event.',
            'Delete',
            answer => {
                if (answer) {
                    this.service.deleteTemplate(this.template.id).subscribe( _ => {
                        this.service.updateList();
                        this.routerService.navigate(['events']);
                    });
                }
            })
    }

    openPopover(popover: NgbPopover) {
        this.popover = popover;
    }

    templateRole(team: EventTemplateTeam, role: Role) {
        return team.roles.find(r => r.role_id === role.id);
    }

    addedEvent(event: Event) {
        this.service.updateList();
        this.template.events.push(event);
        this.template.events.sort((a, b) => a.starts_at > b.starts_at ? 1 : -1);
        this.toastService.showSuccess('Event added: ' + event.name + ' at ' + moment(event.starts_at).format('DD MMM YYYY HH:mm'));
        this.popover.close();
    }

    savedEvent(event: Event) {
        this.service.updateList();
        this.template.events = this.template.events.filter(e => e.id !== event.id);
        this.template.events.push(event);
        this.template.events.sort((a, b) => a.starts_at > b.starts_at ? 1 : -1);
        this.toastService.showSuccess('Event saved: ' + event.name + ' at ' + moment(event.starts_at).format('DD MMM YYYY HH:mm'));
        this.popover.close();
    }

    cancelledPopover() {
        this.popover.close();
    }

    editedTemplate(template: EventTemplate) {
        this.template = template;
        this.popover.close();
    }

    addTeam(team: Team) {
        if (this.template.teams.find(t => t.team_id === team.id) !== undefined) {
            this.popover.close();
            return;
        }

        this.service.addTeam(this.template.id, team.id).subscribe(
            template => {
                this.template = template;
                this.popover.close();
            }
        );
    }

    removeTeam(templateTeam: EventTemplateTeam) {
        this.confirmationService.confirm('remove the team ' + templateTeam.team.name + ' from this event. This will permanently remove it from all future instances of this event.', 'Delete', answer => {
            if (answer) {
                this.service.deleteTeam(this.template.id, templateTeam.team_id).subscribe(
                    template => {
                        this.template = template;
                    }
                );
            }
        });
        return false;
    }

    updateAutogenerateCount(templateTeam: EventTemplateTeam, role: Role, change: number) {
        let peopleCount = 0;
        const templateRole = this.templateRole(templateTeam, role);

        if (templateRole !== undefined) {
            peopleCount = templateRole.autogenerate_people_count;
        }

        if (peopleCount + change < 0) {
            return false;
        }

        this.service.updateRole(this.template.id, role.id, peopleCount + change).subscribe(
            template => {
                this.template = template;
            }
        );

        return false;
    }

    createEvents() {
        const untilDate = '' + this.createUntil.year + '-' + this.createUntil.month + '-' + this.createUntil.day;
        this.service.createEvents(this.template.id, untilDate).subscribe(template => {
            this.template = template;
            this.toastService.show({ body: 'Created event instances for ' + this.template.name });
        });
    }

    recreateRota() {
        this.confirmationService.confirm(
            'delete and re-create all future instances of ' + this.template.name + '.',
            'Re-create',
            answer => {
                if (answer) {
                    this.service.recreateRota(this.template.id).subscribe(template => {
                        this.template = template;
                        this.toastService.show({ body: 'Re-created upcoming events for ' + this.template.name });
                    });
                }
        });
    }

    showEvent(template, eventID) {
        this.eventsService.getEvent(eventID).subscribe(event => {
            this.selectedEvent = event;
            this.modalService.open(template, {size: 'lg'});
        });
    }

    deleteEvent(event) {
        this.confirmationService.confirm(
            'delete the event instance ' + event.name + ' at ' + moment(event.starts_at).format('D MMM HH:mm'),
            'Delete',
            answer => {
                if (answer) {
                    this.eventsService.deleteEvent(event.id).subscribe( _ => {
                        this.toastService.showSuccess('Event ' + event.name + ' at ' + moment(event.starts_at).format('D MMM HH:mm') + ' deleted.');
                        this.deletedEvent(event);
                    });
                }
            }
        );
    }

    deletedEvent(event) {
        this.template.events = this.template.events.filter(e => e.id !== event.id);
    }

    recreateRotaForTeam(team: Team) {
        this.confirmationService.confirm(
            're-create the rota for ' + team.name + ' for all future instances of ' + this.template.name + '.',
            'Re-create',
            answer => {
                if (answer) {
                    this.service.recreateRotaForTeam(this.template.id, team.id).subscribe(result => {
                        this.toastService.show({ body: 'Re-create rota for ' + this.template.name });
                    });
                }
        });
    }

    startEditingDefaultNotes(team?: EventTemplateTeam) {
        if (team === undefined) {
            this.editingDefaultNotes = this.template.default_notes;
            this.editingDefaultNotesTeamID = undefined;
        } else {
            this.editingDefaultNotes = team.default_notes;
            this.editingDefaultNotesTeamID = team.team_id;
        }
    }

    saveDefaultNotes() {
        if (this.editingDefaultNotesTeamID === undefined){
            this.service.updateDefaultNotes(this.template.id, this.editingDefaultNotes).subscribe( _ => {
                this.template.default_notes = this.editingDefaultNotes;
                this.popover.close();
                this.toastService.showSuccess('Default notes updated');
            });
        } else {
            this.service.updateTeamDefaultNotes(this.template.id, this.editingDefaultNotesTeamID, this.editingDefaultNotes)
                .subscribe( _ => {
                    this.template.teams.find(t => t.team_id === this.editingDefaultNotesTeamID).default_notes = this.editingDefaultNotes;
                    this.popover.close();
                    this.toastService.showSuccess('Default notes updated');
            });
        }
    }
}
