<div class="d-flex align-items-start h-100">
    <div class="items-list flex-column" [ngClass]="{'d-none d-sm-flex': selectedPersonID !== null, 'd-flex': selectedPersonID === null}">
        <div class="d-flex flex-wrap align-items-center mb-3" *ngIf="authService.isAdmin">
            <button class="btn btn-success btn-sm shadow me-2" [ngbPopover]="addPersonTemplate" (click)="openAddPersonPopover(p)" #p="ngbPopover">
                <app-svg-icon icon="add" color="white"></app-svg-icon>
                Add new
            </button>
            <button class="btn btn-success btn-sm shadow me-2" (click)="uploadPeople()">Upload</button>
            <a class="btn btn-info btn-sm shadow" href="https://support.plan2gether.com/category/people" target="_blank"><app-svg-icon icon="help" color="white"></app-svg-icon> Help</a>
        </div>

        <div class="border rounded-top shadow-sm bg-white">
            <div class="d-flex flex-wrap align-items-center justify-content-between border-bottom rounded-top p-1 bg-light">
                <span>{{ people.length }} people</span>
                <span class="ms-2"><input type="text" placeholder="Search..." [(ngModel)]="filterText" (input)="updateFilter()" class="form-control form-control-sm"></span>
            </div>
            <ul class="list-group list-group-flush">
                <button type="button" (click)="selectPerson(person)" class="list-group-item list-group-item-action p-1 ps-3 pe-2" [ngClass]="{'bg-medium': selectedPersonID === person.id}" *ngFor="let person of filteredPeople" id="person-{{person.id}}">
                    <div class="d-flex justify-content-between align-items-center">
                        <span>{{ person.first_name }} {{ person.last_name }}</span>
                        <small>{{ person.display_name }}</small>
                    </div>
                    <span *ngIf="person.id === authService.me.id" class="badge bg-info me-1">Me</span>
                    <span *ngIf="authService.me.is_admin && person.user_id !== null" class="badge bg-info me-1">User</span>
                    <span *ngIf="person.is_admin" class="badge bg-info me-1">Admin</span>
                    <span *ngIf="authService.isAdmin && service.duplicatedDisplayNames.includes(person.display_name)" class="badge bg-warning">Display name not unique</span>
                </button>
            </ul>
        </div>
    </div>
    <div class="ps-sm-2 item-details sticky-top" *ngIf="selectedPersonID !== null">
        <div class="d-sm-none mb-3"><a class="btn btn-primary btn-sm shadow" [routerLink]="['/people']">&lt; Back to all people</a></div>
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #addPersonTemplate><app-edit-person (saved)="addedPerson($event)"></app-edit-person></ng-template>
