import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Role } from './models/role.model';
import { Observable } from 'rxjs';
import { Team } from './models/team.model';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    constructor(private api: ApiService) { }

    addRole(name: string, team: Team) {
        return new Observable<Role>(subscriber => {
            return this.api.post<Role>('roles', { name, team: team.id })
                .subscribe(data => {
                    subscriber.next(data);
                    subscriber.complete();
                }, error => {
                    subscriber.next(null);
                    subscriber.complete();
                });
        });
    }

    getRole(id: string) {
        return this.api.get<Role>('roles/' + id);
    }

    updateRole(role: Role) {
        return this.api.put<Role>('roles/' + role.id, role);
    }

    deleteRole(id: string) {
        return this.api.delete('roles/' + id);
    }

    addPerson(roleID: string, personID: string) {
        return this.api.post<Role>('roles/' + roleID + '/people', {id: personID});
    }

    removePerson(roleID: string, personID: string) {
        return this.api.delete<Role>('roles/' + roleID + '/people/' + personID);
    }

    updatePerson(roleID: string, personID: string, reserve: boolean) {
        return this.api.put<Role>('roles/' + roleID + '/people/' + personID, {reserve});
    }
}
