import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Unavailability } from './models/unavailability.model';

@Injectable({
    providedIn: 'root'
})
export class UnavailabilitiesService {

    constructor(private api: ApiService) { }

    listUnavailabilities(personID: string) {
        return this.api.get<Unavailability[]>('people/' + personID + '/unavailabilities');
    }

    addUnavailability(unavailability: Unavailability) {
        return this.api.post<Unavailability>('people/' + unavailability.person_id + '/unavailabilities', unavailability);
    }

    deleteUnavailability(unavailability: Unavailability) {
        return this.api.delete('people/' + unavailability.person_id + '/unavailabilities/' + unavailability.id);
    }

    checkPeopleForAvailability(personIDs: string[], start: string, end: string) {
        const queryString = new HttpParams({fromObject: {
            'people[]': personIDs,
            start,
            end
        }}).toString();
        return this.api.get<string[]>('unavailable-people?' + queryString);
    }
}
