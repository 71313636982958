<div class="d-flex flex-wrap align-items-center mb-3">
    <a class="btn btn-info btn-sm shadow" href="https://support.plan2gether.com/category/organisation" target="_blank"><app-svg-icon icon="help" color="white"></app-svg-icon> Help</a>
</div>
<div class="d-flex align-items-start flex-wrap">
    <div class="item-details border rounded shadow-sm bg-white mb-3" *ngIf="organisation != null">
        <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
            <h3 class="p-0">{{ organisation.name }}</h3>
        </div>
        <div class="p-3">
            <div class="d-flex flex-column overflow-auto">
                <div class="d-flex">
                    <div class="item-info-label">Created:</div>
                    <div class="item-info-details">{{ organisation.created_at | date:'EEE, dd MMM y' }}</div>
                </div>
                <div class="d-flex">
                    <div class="item-info-label">URL:</div>
                    <div class="item-info-details">{{ organisation.url }}.plan2gether.com</div>
                </div>
                <div class="d-flex">
                    <div class="item-info-label">Short URL:</div>
                    <div class="item-info-details">p2g.cc/{{ organisation.url_shortcode }}</div>
                </div>
                <div class="d-flex">
                    <div class="item-info-label">Subscription:</div>
                    <div class="item-info-details" >
                        {{ organisation.has_active_subscription ? 'Active' : 'None' }}
                        <button class="btn btn-sm btn-outline-primary ms-2" (click)="manageSubscription()" *ngIf="organisation.billing_user_id === authService.me.user_id">Manage Subscription</button>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="item-info-label">SMS credits:</div>
                    <div class="item-info-details">{{ organisation.sms_credits }} remaining <button class="btn btn-sm btn-outline-primary ms-2" (click)="buyCredits()" *ngIf="organisation.billing_user_id === authService.me.user_id"><app-svg-icon icon="coin"></app-svg-icon> Buy credits</button></div>
                </div>
                <div class="d-flex">
                    <div class="item-info-label">Logs:</div>
                    <div class="item-info-details"><a [routerLink]="['/logs']"  class="btn btn-sm btn-outline-primary">View logs</a></div>
                </div>
            </div>
        </div>
    </div>

    <div class="border rounded shadow-sm bg-white mx-3" *ngIf="organisation != null">
        <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
            <h3 class="p-0">Preferences</h3>
        </div>
        <div class="p-3">
            <div class="d-flex flex-column overflow-auto">
                <div class="d-flex">
                    <div class="item-info-label">Create recurring events:</div>
                    <div class="item-info-details">
                        <div class="input-group input-group-sm">
                            <input class="form-control" type="text" (keyup)="hasChanges = true;" [(ngModel)]="organisation.preferences.days_to_generate">
                            <span class="input-group-text">days ahead</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="item-info-label">Send reminders:</div>
                    <div class="item-info-details">
                        <div ngbDropdown container="body" class="input-group input-group-sm">
                            <input class="form-control" type="text" (keyup)="hasChanges = true;" [(ngModel)]="reminderNumber">
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">{{ reminderMultiplier === 1 ? 'hours' : 'days'}} before</button>
                            <div class="dropdown-menu" ngbDropdownMenu>
                                <button class="dropdown-item" (click)="setReminderMultiplier(1)">hours before</button>
                                <button class="dropdown-item" (click)="setReminderMultiplier(24)">days before</button>
                            </div>
                        </div>
                        <div class="mb-3 form-check my-1">
                            <input type="checkbox" class="form-check-input" id="check-send-sms" (click)="hasChanges = true;" [(ngModel)]="organisation.preferences.send_sms">
                            <label class="form-check-label" for="check-send-sms">via SMS</label>
                        </div>
                        <div class="mb-3 form-check mt-1">
                            <input type="checkbox" class="form-check-input" id="check-send-email" (click)="hasChanges = true;" [(ngModel)]="organisation.preferences.send_email">
                            <label class="form-check-label" for="check-send-email">via Email</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="item-info-label">Welcome email:</div>
                    <div class="item-info-details">
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="check-send-email" (click)="hasChanges = true;" [(ngModel)]="organisation.preferences.people_can_become_users">
                            <label class="form-check-label" for="check-send-email">Include link to create login</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="item-info-label">Rota privacy:</div>
                    <div class="item-info-details">
                        <div class="mb-2 form-check">
                            <input type="checkbox" id="check-hide-rota" class="form-check-input" (click)="hasChanges = true;" [(ngModel)]="organisation.preferences.rota_is_private">
                            <label for="check-hide-rota" class="form-check-label">Hide rota unless logged in</label>
                        </div>
                        <div class="mb-3 form-check">
                            <input type="checkbox" id="check-rota-passcode" class="form-check-input" (click)="hasChanges = true;" [(ngModel)]="organisation.preferences.rota_allow_passcode" [disabled]="!organisation.preferences.rota_is_private">
                            <label for="check-rota-passcode" class="form-check-label">
                                Allow access with passcode:
                                <input type="text" class="form-control form-control-sm" [disabled]="!organisation.preferences.rota_allow_passcode || !organisation.preferences.rota_is_private" [(ngModel)]="organisation.preferences.rota_passcode" (keyup)="hasChanges = true;">
                            </label>
                        </div>
                        
                    </div>
                </div>
                <div class="item-info-details"><button class="btn btn-sm btn-primary shadow-sm" [disabled]="!hasChanges" (click)="savePreferences()">Save changes</button></div>
            </div>
        </div>
    </div>
</div>
