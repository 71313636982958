import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Event } from 'src/app/api/models/event.model';
import { EventsService } from 'src/app/api/events.service';
import { Team } from 'src/app/api/models/team.model';
import { EventTemplate } from 'src/app/api/models/eventtemplate.model';
import { HintService } from 'src/app/common-components/hint.service';
import { EventUpdatesService } from 'src/app/websockets/event-updates.service';
import { ToastService } from 'src/app/common-components/toast.service';
import { Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

enum ViewType {
    list = "list",
    calendar = "calendar",
    grid = "grid"
}

@Component({
    selector: 'app-rota-page',
    templateUrl: './rota-page.component.html',
    styleUrls: ['./rota-page.component.css']
})
export class RotaPageComponent implements OnInit {

    events: Event[] = [];

    filteredEvents: Event[] = [];

    viewType: ViewType = ViewType.list;
    teams: Team[] = [];
    templates: EventTemplate[] = [];
    filteredTeamIDs: string[] = [];
    filteredTemplateIDs: string[] = [];
    startDate?: string = null;
    endDate?: string = null;

    errorPasscodeRequired = false;
    rotaPasscode?: string = undefined;

    popover: NgbPopover;

    constructor(
        private service: EventsService,
        private hintService: HintService,
        private updatesService: EventUpdatesService,
        private toastService: ToastService,
        private router: Router
    ) {
        const filteredTeams = localStorage.getItem('rotaFilteredTeams');

        if (filteredTeams !== null) {
            this.filteredTeamIDs = filteredTeams.split(',').filter(id => id.length > 0);
        }

        const filteredTemplates = localStorage.getItem('rotaFilteredTemplates');

        if (filteredTemplates !== null) {
            this.filteredTemplateIDs = filteredTemplates.split(',').filter(id => id.length > 0);
        }

        const storageViewType = localStorage.getItem('rotaViewType');

        if (['list', 'calendar', 'grid'].includes(storageViewType)) {
            this.viewType = storageViewType as ViewType;
        }

        this.startDate = sessionStorage.getItem('startDate');

        this.endDate = sessionStorage.getItem('endDate');

        this.updateFromServer();
    }

    updateFromServer() {
        this.errorPasscodeRequired = false;

        this.service.listEvents(this.startDate, this.endDate, this.rotaPasscode).subscribe(
            resource => {
                if (resource.error !== undefined) {
                    if (resource.error.status === 401) {
                        this.toastService.showError('Please log in to view rota');
                        this.router.navigateByUrl('login');
                    } else if (resource.error.status === 403) {
                        this.errorPasscodeRequired = true;
                    }
                    return;
                }
                this.events = resource.data;
                this.teams = [];
                this.templates = [];
                this.events.forEach(event => {
                    event.teams.forEach(eventTeam => {
                        if (this.teams.find(team => team.id === eventTeam.team_id) === undefined && eventTeam.team !== null) {
                            this.teams.push(eventTeam.team);
                        }
                    });
                    if (this.templates.find(template => template.id === event.event_template_id) === undefined) {
                        this.templates.push(event.event_template);
                    }
                });
                this.teams.sort((a, b) => a.name > b.name ? 1 : -1);
                this.templates.sort((a, b) => a.name > b.name ? 1 : -1);
                this.filterEvents();
            }
        );
    }

    ngOnInit() {
        this.updatesService.eventCreated.subscribe(event => {
            this.events.push(event);
            this.events.sort((a, b) => a.starts_at.localeCompare(b.starts_at))
            this.filterEvents();
        });
    }

    eventDeleted(event: Event) {
        this.events = this.events.filter(e => e.id !== event.id );
        this.filteredEvents = this.filteredEvents.filter(e => e.id !== event.id);
    }

    filterEvents() {
        if (this.events.length === 0) {
            this.filteredEvents = [];
            this.hintService.showHint('Nothing to show based on current filters. <br><br>Need help getting started?<br><a href="https://support.plan2gether.com" target="_blank">Visit our support pages.</a>');
            return;
        }

        this.filteredEvents = this.events.filter(event => {
            if (this.filteredTemplateIDs.includes(event.event_template_id)) {
                return false;
            }
            if (event.teams.length === 0) {
                return true;
            }
            return event.teams.filter(eventTeam => !this.filteredTeamIDs.includes(eventTeam.team_id)).length > 0;
        });

        if (this.filteredEvents.length === 0 && this.events.length > 0) {
            this.hintService.showHint('Nothing to show. Try changing your filters.');
        } else {
            this.hintService.hideHint();
        }
    }

    updateFilteredTeams(id: string) {
        if (this.filteredTeamIDs.includes(id)) {
            this.filteredTeamIDs.splice(this.filteredTeamIDs.indexOf(id), 1);
        } else {
            this.filteredTeamIDs.push(id);
        }

        localStorage.setItem('rotaFilteredTeams', this.filteredTeamIDs.join(','));

        this.filterEvents();
    }

    clearFilteredTeams() {
        this.filteredTeamIDs = [];
        localStorage.removeItem('rotaFilteredTeams');

        this.filterEvents();
    }

    updateFilteredTemplates(id: string) {
        if (this.filteredTemplateIDs.includes(id)) {
            this.filteredTemplateIDs.splice(this.filteredTemplateIDs.indexOf(id), 1);
        } else {
            this.filteredTemplateIDs.push(id);
        }

        localStorage.setItem('rotaFilteredTemplates', this.filteredTemplateIDs.join(','));

        this.filterEvents();
    }

    clearFilteredTemplates() {
        this.filteredTemplateIDs = [];
        localStorage.removeItem('rotaFilteredTemplates');

        this.filterEvents();
    }

    updateDates(data: {start: string, end: string}) {
        this.startDate = data.start;
        this.endDate = data.end;
        this.updateFromServer();
    }

    openViewPopover(popover: NgbPopover) {
        this.popover = popover;
    }

    changeView(type: string) {
        this.viewType = type as ViewType
        localStorage.setItem('rotaViewType', this.viewType);
        this.popover.close();
    }

    passcodeEntered(passcode: string) {
        this.rotaPasscode = passcode;

        this.updateFromServer();
    }

    print() {
        window.print();
    }
}
