import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    toasts: AppToast[] = [];

    show(toast: AppToast) {
        this.toasts.push(toast);
    }

    showSuccess(body: string) {
        this.toasts.push({body, class: 'bg-success text-light'});
    }

    showError(body: string) {
        this.toasts.push({body, class: 'bg-danger text-light'});
    }

    remove(toast: AppToast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }
}

export interface AppToast {
    body: string;
    autohide?: boolean;
    class?: string;
}