import { Role } from './role.model';
import { Event } from './event.model';
import { Team } from './team.model';
import { Organisation } from './organisation.model';

function nth(day) {
    if (day > 3 && day < 21) {
        return 'th';
    }
    switch (day % 10) {
        case 1:  return 'st';
        case 2:  return 'nd';
        case 3:  return 'rd';
        default: return 'th';
    }
}

// tslint:disable:variable-name
export class EventTemplate {
    id?: string;
    name: string;
    recurring: boolean;
    date?: string;
    start_time?: number;
    end_time?: number;
    recur_period?: number;
    recur_days?: number;
    recur_weeks?: number;
    next_scheduled_at?: string;
    created_at?: string;
    updated_at?: string;
    suppress_reminders?: boolean;
    remind_hours_before?: number;
    default_notes?: string;

    updateUpcoming?: boolean; // Not part of the model, but used when updating a template

    organisation?: Organisation;
    teams?: EventTemplateTeam[];
    private _events?: Event[];

    set events(events: Event[]) {
        this._events = [];
        events.forEach(e => this._events.push(Object.assign(new Event(), e)));
    }

    get events(): Event[] {
        return this._events;
    }

    get display_days(): string {
        switch (this.recur_period) {
            case 1:
                return 'Daily';
            case 7:
                if (this.recur_weeks == null || this.recur_weeks == 0) {
                    return 'Every ' + ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][this.recur_days - 1];
                } else {
                    var weeks = [];
                    if (this.recur_weeks & 1) weeks.push('first');
                    if (this.recur_weeks & 2) weeks.push('second');
                    if (this.recur_weeks & 4) weeks.push('third');
                    if (this.recur_weeks & 8) weeks.push('fourth');
                    if (this.recur_weeks & 16) weeks.push('fifth');
                    if (this.recur_weeks & 32) weeks.push('last');
                    return 'The ' + weeks.join(', ') + ' ' + ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][this.recur_days - 1] + ' of each month';
                }
            case 30:
                return 'The ' + this.recur_days + nth(this.recur_days) + ' of every month';
            default:
                return 'Every ' + this.recur_period + ' days';
        }
    }

    get display_times(): string {
        const startHour = Math.floor(this.start_time / 60);
        const startMinutes = this.start_time % 60;
        const endHour = Math.floor(this.end_time / 60);
        const endMinutes = this.end_time % 60;

        function toString(num: number) { return num > 9 ? '' + num : '0' + num; }

        return toString(startHour) + ':' + toString(startMinutes) + ' - ' + toString(endHour) + ':' + toString(endMinutes);
    }
}
// tslint:enable:variable-name

export interface EventTemplateTeam {
    id: string;
    event_template_id: string;
    team_id: string;
    default_notes?: string;
    created_at: string;
    updated_at: string;

    team: Team;
    roles: EventTemplateTeamRole[];
}

export interface EventTemplateTeamRole {
    id: string;
    event_template_team_id: string;
    role_id: string;
    autogenerate_people_count: number;
    created_at: string;
    updated_at: string;

    role: Role;
}
