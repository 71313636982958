import { Injectable } from '@angular/core';
import { ApiModule } from './api.module';
import { ApiService } from './api.service';
import { Event, AddingEvent } from './models/event.model';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ResourceWithError } from './interfaces/resourcewitherror';

@Injectable({
    providedIn: ApiModule
})
export class EventsService {

    constructor(private api: ApiService) { }

    listEvents(start?: string, end?: string, passcode?: string) {

        const parameters = new URLSearchParams();

        if (start !== null) {
            parameters.append('start', start);
        }

        if (end !== null) {
            parameters.append('end', end);
        }

        if (passcode !== undefined) {
            parameters.append('passcode', passcode);
        }

        let resource = 'events?' + parameters.toString();
        if (!this.api.isAuthenticated) {
            resource = 'rota/' + window.location.hostname.split('.')[0] + '?' + parameters.toString();
        }

        return new Observable<ResourceWithError<Event[]>>(subscriber => {
            this.api.get<Event[]>(resource, this.api.isAuthenticated)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        subscriber.next({data: null, error});
                        subscriber.complete();
                        return throwError(error);
                    })
                )
                .subscribe(data => {
                    subscriber.next({data: data.map(e => Object.assign(new Event(), e))});
                    subscriber.complete();
                });
        });
    }

    getEvent(id: string) {
        return new Observable<Event>(subscriber => {
            this.api.get<Event>('events/' + id)
                .subscribe(data => {
                    subscriber.next(Object.assign(new Event(), data));
                    subscriber.complete();
                });
        });
    }

    addEvent(event: AddingEvent) {
        return new Observable<Event>(subscriber => {
            this.api.post<Event>('events', event)
                .subscribe(data => {
                    subscriber.next(Object.assign(new Event(), data));
                });
        });
    }

    updateEvent(eventID: string, event: AddingEvent) {
        return new Observable<Event>(subscriber => {
            this.api.put<Event>('events/' + eventID, event)
                .subscribe(data => {
                    subscriber.next(Object.assign(new Event(), data));
                });
        });
    }

    deleteEvent(eventID: string) {
        return this.api.delete('events/' + eventID);
    }

    updateNotes(eventID: string, notes: string) {
        return this.api.post<Event>('events/' + eventID + '/notes', { notes });
    }

    updateTeamNotes(eventID: string, teamID: string, notes: string) {
        return this.api.post<Event>('events/' + eventID + '/teams/' + teamID + '/notes', { notes });
    }

    sendNotes(eventID: string) {
        return this.api.post('events/' + eventID + '/notes/send', {});
    }

    sendTeamNotes(eventID: string, teamID: string) {
        return this.api.post('events/' + eventID + '/teams/' + teamID + '/notes/send', {});
    }
}
