import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Team } from 'src/app/api/models/team.model';
import { TeamsService } from 'src/app/api/teams.service';

@Component({
    selector: 'app-edit-team',
    templateUrl: './edit-team.component.html'
})
export class EditTeamComponent implements OnInit, AfterViewInit {

    teamForm = this.formBuilder.group({
        name: ['', Validators.required],
        single_role: [true] 
    });

    buttonText = 'Add';

    @Input() team?: Team = null;

    @Output() teamSaved = new EventEmitter<Team>();

    @ViewChild('name') nameElement: ElementRef;

    constructor(
        private service: TeamsService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        if (this.team !== null) {
            this.teamForm.setValue({name: this.team.name, single_role: this.team.single_role});
            this.buttonText = 'Save';
        }
    }

    ngAfterViewInit(): void {
        this.nameElement.nativeElement.focus();
    }

    save() {
        if (this.team === null) {
            this.service.addTeam(this.teamForm.value as Team).subscribe(
                team => {
                    if (team !== null) {
                        this.teamSaved.emit(team);
                    }
                }
            );
        } else {
            this.team = Object.assign(this.team, this.teamForm.value);
            this.service.updateTeam(this.team).subscribe(
                team => {
                    if (team !== null) {
                        this.teamSaved.emit(team);
                    }
                }
            );
        }
    }
}
