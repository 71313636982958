import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RotaModule } from './rota/rota.module';
import { LoginPageComponent } from './login-page/login-page.component';
import { ApiModule } from './api/api.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PeopleModule } from './people/people.module';
import { AuthGuard } from './auth.guard';
import { TeamsModule } from './teams/teams.module';
import { EventsModule } from './events/events.module';
import { RolesModule } from './roles/roles.module';
import { CommonComponentsModule } from './common-components/common-components.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OrganisationModule } from './organisation/organisation.module';
import { TopbarComponent } from './topbar/topbar.component';
import { NotificationsModule } from './notifications/notifications.module';
import { LaunchModule } from './launch/launch.module';

@NgModule({
    declarations: [
        AppComponent,
        LoginPageComponent,
        SidebarComponent,
        TopbarComponent
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        AppRoutingModule,
        NgbModule,
        RotaModule,
        ApiModule,
        PeopleModule,
        TeamsModule,
        EventsModule,
        RolesModule,
        CommonComponentsModule,
        OrganisationModule,
        NotificationsModule,
        LaunchModule
    ],
    providers: [AuthGuard],
    bootstrap: [AppComponent]
})
export class AppModule { }
