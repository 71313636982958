<div class="overflow-auto wrapper pe-3 pb-3">
    <table class="table table-bordered table-sm bg-white border rounded shadow-sm">
        <thead>
            <tr>
                <th class="edit-cell p-3" rowspan="2">
                    <button class="btn btn-sm btn-warning" *ngIf="canEdit() && !editing" (click)="editing = true"><app-svg-icon icon="pencil"></app-svg-icon> Edit Rota</button>
                    <button class="btn btn-sm btn-success" *ngIf="editing" (click)="editing = false">Done</button>
                </th>
                <th class="team-name" *ngFor="let team of teams" [attr.colspan]="team.roles.length" [attr.rowspan]="team.single_role ? 2 : 1"><h5 class="p-2 me-1 mb-0">{{ team.name }}</h5></th>
            </tr>
            <tr>
                <ng-container *ngFor="let role of roles"><th *ngIf="!roleIsInSingleRoleTeam(role)" class="text-muted role-name">{{ role.name }}</th></ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let event of events">
                <td class="event-name px-2">
                    <h4>{{ event.name }}</h4>
                    {{ event.starts_at | date:"EEE, dd MMM HH:mm" }} - {{ event.ends_at | date:"HH:mm" }}
                </td>
                <td *ngFor="let role of roles" [ngClass]="{'bg-light': !eventHasTeam(event, role.team_id)}">
                    <div *ngFor="let eventPerson of peopleForEventRole(event, role)"><app-rota-person [event]="event" [eventPerson]="eventPerson" [role]="role" [editing]="editing" (changed)="changedPerson($event)"></app-rota-person></div>
                    <div *ngIf="eventHasTeam(event, role.team_id)"><app-rota-person [event]="event" [role]="role" [editing]="editing" (changed)="changedPerson($event)"></app-rota-person></div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
