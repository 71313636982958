<div class="mb-3">
    <label class="form-label">Name:</label>
    <input type="text" class="form-control form-control-sm" #name placeholder="New Event" [(ngModel)]="eventName">
</div>
<div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="recurringCheck" [(ngModel)]="recurring">
    <label class="form-check-label" for="recurringCheck">Recurring</label>
</div>
<div class="mb-3" *ngIf="!recurring && template === undefined">
    <label class="form-label">Date:</label>
    <div class="input-group input-group-sm mb-1">
        <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><app-svg-icon icon="calendar"></app-svg-icon></button>
    </div>
</div>
<div class="mb-3">
    <label class="form-label">Times:</label>
    <app-time-picker [startTime]="startTime" [endTime]="endTime" (startTimeUpdated)="timeUpdated('start', $event)" (endTimeUpdated)="timeUpdated('end', $event)"></app-time-picker>
</div>
<div class="mb-3" *ngIf="recurring">
    <label class="form-label">Repeat:</label>
    <select class="form-select form-select-sm mb-3" [(ngModel)]="recurPeriod" (change)="updateRecurDayOptions();generateNextScheduledAtOptions()">
        <option value="1">Daily</option>
        <option value="7">Weekly</option>
        <option value="14">Every 2 weeks</option>
        <option value="21">Every 3 weeks</option>
        <option value="28">Every 4 weeks</option>
        <option value="30">Monthly</option>
        <option value="107">On certain days of the month</option>
    </select>
    <select class="form-select form-select-sm mb-1" [(ngModel)]="recurDays" *ngIf="recurPeriod == 7 || recurPeriod == 107 || recurPeriod == 30">
        <option value="{{ option.value }}" *ngFor="let option of recurDaysOptions">On {{ recurPeriod == 30 ? 'the' : '' }} {{ option.text }}</option>
    </select>
    <div class="input-group input-group-sm mb-1" *ngIf="recurPeriod == 107">
        <label class="form-label">On the following {{ recurDaysOptions[recurDays-1].text }}s of every month:</label>
        <div class="mb-0 me-3 form-check">
            <input type="checkbox" class="form-check-input" id="recurWeeksFirst" [(ngModel)]="recurWeekCheckboxes.first">
            <label class="form-check-label" for="recurWeeksFirst">First</label>
        </div>
        <div class="mb-0 me-3 form-check">
            <input type="checkbox" class="form-check-input" id="recurWeeksSecond" [(ngModel)]="recurWeekCheckboxes.second">
            <label class="form-check-label" for="recurWeeksSecond">Second</label>
        </div>
        <div class="mb-0 me-3 form-check">
            <input type="checkbox" class="form-check-input" id="recurWeeksThird" [(ngModel)]="recurWeekCheckboxes.third">
            <label class="form-check-label" for="recurWeeksThird">Third</label>
        </div>
        <div class="mb-0 me-3 form-check">
            <input type="checkbox" class="form-check-input" id="recurWeeksFourth" [(ngModel)]="recurWeekCheckboxes.fourth">
            <label class="form-check-label" for="recurWeeksFourth">Fourth</label>
        </div>
        <div class="mb-0 me-3 form-check">
            <input type="checkbox" class="form-check-input" id="recurWeeksFifth" [(ngModel)]="recurWeekCheckboxes.fifth">
            <label class="form-check-label" for="recurWeeksFifth">Fifth</label>
        </div>
        <div class="mb-0 me-3 form-check">
            <input type="checkbox" class="form-check-input" id="recurWeeksLast" [(ngModel)]="recurWeekCheckboxes.last">
            <label class="form-check-label" for="recurWeeksLast">Last</label>
        </div>
    </div>
    <div class="input-group input-group-sm mb-1" *ngIf="recurPeriod == 14 || recurPeriod == 21 || recurPeriod == 28">
        <span class="input-group-text">Starting:</span>
        <select class="form-select" [(ngModel)]="nextScheduledAt">
            <option value="{{ option.format('YYYY-MM-DD') }}" *ngFor="let option of nextScheduledAtOptions">{{ option.format('ddd Do MMMM YYYY') }}</option>
        </select>
    </div>
</div>
<div class="mb-3">
    <label class="form-label">Send reminders:</label>
    <select class="form-select form-select-sm mb-1" [(ngModel)]="selectedReminderOption" (change)="updateReminderOptions()">
        <option value="{{ ReminderOptions.organisationDefault }}">Use organisation setting</option>
        <option value="{{ ReminderOptions.suppress }}">Don't send</option>
        <option value="{{ ReminderOptions.specifyHoursBefore }}">Specify time:</option>
    </select>
    <div ngbDropdown class="input-group input-group-sm" *ngIf="selectedReminderOption === ReminderOptions.specifyHoursBefore">
        <input class="form-control" type="text" [(ngModel)]="reminderNumber">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">{{ reminderMultiplier === 1 ? 'hours' : 'days'}} before</button>
        <div class="dropdown-menu" ngbDropdownMenu>
            <button class="dropdown-item" (click)="setReminderMultiplier(1)">hours before</button>
            <button class="dropdown-item" (click)="setReminderMultiplier(24)">days before</button>
        </div>
    </div>
</div>
<div class="mb-3 form-check" *ngIf="template !== undefined">
    <input type="checkbox" class="form-check-input" id="updateUpcomingCheck" [(ngModel)]="updateUpcoming">
    <label class="form-check-label" for="updateUpcomingCheck">Update name and times of upcoming events</label>
</div>
<div class="alert alert-danger" *ngIf="formError">{{ formError }}</div>
<div class="d-flex align-items-start justify-content-end">
    <button class="btn btn-sm btn-success me-1" (click)="addTemplate()">{{ template === undefined ? 'Add' : 'Save'}}</button> 
    <button class="btn btn-sm btn-outline-danger" (click)="cancel()">Cancel</button>
</div>
