<app-sidebar #sidebar (widthChanged)="leftMarginChanged($event)"></app-sidebar>
<app-topbar [sidebar]="sidebar"></app-topbar>
<div class="main p-3" style="width: calc(100% - {{ apiService.isAuthenticated ? sidebar.width : 0 }}px);left: {{ apiService.isAuthenticated ? sidebar.width : 0 }}px;">
    <router-outlet></router-outlet>
</div>

<app-splash-screen></app-splash-screen>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<app-confirmation></app-confirmation>
<app-hint></app-hint>
<app-subscription-prompt></app-subscription-prompt>
