<div class="border rounded shadow-sm bg-white" *ngIf="person != null">
    <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
        <h3 class="p-0">{{ person.first_name }} {{ person.last_name }}</h3>
    </div>
    <div class="px-3 pt-2 pb-0">
        <span class="badge bg-info me-1" *ngIf="person.id === authService.me.id">Me</span>
        <span class="badge bg-info me-1" *ngIf="person.user_id !== null">User</span>
        <span class="badge bg-info me-1" *ngIf="person.is_admin">Admin</span>
        <span class="badge bg-info" *ngIf="authService.isAdmin && service.duplicatedDisplayNames.includes(person.display_name)">Display name not unique</span>
    </div>
    <div class="p-3">
        <button class="btn btn-warning btn-sm shadow-sm me-1" *ngIf="authService.isAdmin || authService.me.id === person.id" (click)="openPopover(p)" #p="ngbPopover" [ngbPopover]="editPersonTemplate" placement="bottom" popoverTitle="Editing {{ person.first_name }} {{ person.last_name }}">Edit</button>
        <button class="btn btn-danger btn-sm shadow-sm me-1" *ngIf="authService.isAdmin && !person.is_admin" (click)="deletePerson()">Delete</button>
        <button class="btn btn-primary btn-sm shadow-sm" *ngIf="authService.isAdmin && person.user_id === null" (click)="sendWelcomeEmail()">{{ person.welcome_email_sent_at === null ? 'Send' : 'Re-send'}} Welcome Email</button>
    </div>
    <div class="p-3 border-top">
        <div class="d-flex flex-column overflow-auto">
            <div class="d-flex">
                <div class="item-info-label">Full name:</div>
                <div class="item-info-details">
                    <span class="me-2">{{ person.first_name }} {{ person.last_name }}</span>
                    <app-svg-icon icon="private" tooltip="Only visible by logged-in users" color="info"></app-svg-icon>
                </div>
            </div>
            <div class="d-flex">
                <div class="item-info-label">Display name:</div>
                <div class="item-info-details">
                    <span class="me-2">{{ person.display_name }}</span>
                    <app-svg-icon icon="visibility" tooltip="Publicly visible on rota" color="info"></app-svg-icon>
                </div>
            </div>
            <div class="d-flex" *ngIf="person.has_email">
                <div class="item-info-label">Email:</div>
                <div class="item-info-details">
                    <span class="me-2">{{ person.email || 'private' }}</span>
                    <app-svg-icon icon="private" tooltip="Only visible by admin" color="info"></app-svg-icon>
                </div>
            </div>
            <div class="d-flex" *ngIf="!person.has_email">
                <div class="item-info-label">Email:</div>
                <div class="item-info-details">none</div>
            </div>
            <div class="d-flex" *ngIf="person.has_phone">
                <div class="item-info-label">Phone:</div>
                <div class="item-info-details">
                    <span class="me-2">{{ person.phone || 'private' }}</span>
                    <app-svg-icon icon="private" tooltip="Only visible by admin" color="info"></app-svg-icon>
                </div>
            </div>
            <div class="d-flex" *ngIf="!person.has_phone">
                <div class="item-info-label">Phone:</div>
                <div class="item-info-details">none</div>
            </div>
            <div class="d-flex" *ngIf="person.id === authService.me.id">
                <div class="item-info-label">User profile:</div>
                <div class="item-info-details"><button class="btn btn-sm btn-outline-primary" (click)="userProfile()">Manage my user profile</button></div>
            </div>
            <div class="d-flex" *ngIf="person.teams !== null && person.teams.length > 0">
                <div class="item-info-label">Teams:</div>
                <div class="item-info-details"><a class="badge bg-info me-1" *ngFor="let team of person.teams" [routerLink]="['/teams', team.id]">{{ team.name }} {{ team.pivot.is_admin ? '(Manager)' : '' }}</a></div>
            </div>
            <div class="d-flex" *ngIf="person.roles !== null && person.roles.length > 0">
                <div class="item-info-label">Roles:</div>
                <div class="item-info-details"><a class="badge bg-info me-1" *ngFor="let role of person.roles" [routerLink]="['/roles', role.id]">{{ role.team.single_role ? '' : role.team.name + ' - ' }} {{ role.name }} {{ role.pivot.reserve ? '(Reserve)' : '' }}</a></div>
            </div>
        </div>
    </div>
</div>

<div class="border rounded shadow-sm bg-white mt-3" *ngIf="person !== undefined && (person.id === authService.me.id || authService.isAdmin)">
    <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
        <h5 class="p-0">Availability
            <button type="button" class="btn btn-sm btn-success shadow-sm ms-2" placement="right" #p="ngbPopover" (click)="openPopover(p)" [ngbPopover]="addUnavailabilityTemplate"><app-svg-icon icon="add" color="white"></app-svg-icon> Add dates</button>
        </h5>
    </div>
    <div class="d-flex align-items-center justify-content-between p-2 border-bottom" *ngFor="let unavailability of unavailabilities">
        <span>Unavailable from {{ unavailability.starts_at | date:"EEE, dd MMM y" }} to {{ unavailability.ends_at | date:"EEE, dd MMM y" }}</span>
        <button class="btn btn-sm btn-danger" (click)="deleteUnavailability(unavailability)"><app-svg-icon icon="delete" color="white"></app-svg-icon> Delete</button>
    </div>
</div>

<div class="border rounded shadow-sm bg-white mt-3" *ngIf="upcomingEvents.length > 0">
    <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
        <h5 class="p-0">Upcoming events</h5>
    </div>
    <div class="d-flex align-items-center justify-content-between p-2 border-bottom" *ngFor="let event of upcomingEvents">
        <div class="d-flex flex-column">
            <span class="fs-5 me-2 my-0">{{ event.name }}</span>
            {{ event.starts_at | date:"EEE, dd MMM y HH:mm" }} - {{ event.ends_at | date:"HH:mm" }}
        </div>
        <div class="text-end flex-grow-1 me-2">
            <span *ngFor="let eventPerson of event.people; let isLast = last">{{ eventPerson.event_team_role.event_team.name }}: {{ eventPerson.event_team_role.name }}<br *ngIf="!isLast"></span>
        </div>
        <div class="flex-shrink-1">
            <button class="btn btn-primary" (click)="showEvent(eventModal, event.id)">View</button>
        </div>
    </div>
</div>

<ng-template #editPersonTemplate>
    <app-edit-person [person]="person" (saved)="editedPerson($event)"></app-edit-person>
</ng-template>

<ng-template #addUnavailabilityTemplate>
    <p>{{ person.first_name }} {{ person.last_name }} is unavailable and won't be added to a rota on these dates:</p>
    <div class="mb-3">
        <label class="form-label">From:</label>
        <div class="input-group input-group-sm mb-1">
            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="unavailabilityStart" ngbDatepicker #ds="ngbDatepicker">
            <button class="btn btn-outline-secondary" (click)="ds.toggle()" type="button"><app-svg-icon icon="calendar"></app-svg-icon></button>
        </div>
    </div>
    <div class="mb-3">
        <label class="form-label">Until:</label>
        <div class="input-group input-group-sm mb-1">
            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="unavailabilityEnd" ngbDatepicker #de="ngbDatepicker">
            <button class="btn btn-outline-secondary" (click)="de.toggle()" type="button"><app-svg-icon icon="calendar"></app-svg-icon></button>
        </div>
    </div>
    <div class="d-flex align-items-start justify-content-end">
        <button class="btn btn-sm btn-success me-1" (click)="addUnavailability()">Save</button>
    </div>
</ng-template>

<ng-template #eventModal>
    <app-rota-event [event]="selectedEvent" [filteredTeams]="[]" [inList]="false"></app-rota-event>
</ng-template>
