import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationService {

    confimationText = '';
    yesText = 'Yes';
    confirmed: (answer: boolean) => void;
    cancelled: () => void;

    visible = false;

    constructor() { }

    confirm(text: string, yesText: string, confirmed: (answer: boolean) => void, cancelled: () => void = () => {}) {
        this.confimationText = text;
        this.yesText = yesText;
        this.confirmed = confirmed;
        this.cancelled = cancelled;
        this.visible = true;
    }
}
