import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonSelectorComponent } from './person-selector/person-selector.component';
import { CommonComponentsModule } from '../common-components/common-components.module';



@NgModule({
  declarations: [PersonSelectorComponent],
  imports: [
    CommonModule,
    CommonComponentsModule
  ],
  exports: [
    PersonSelectorComponent
  ]
})
export class PersonSelectorModule { }
