<div class="d-flex flex-column align-items-start" *ngIf="role !== undefined">
    <div class="mb-3"><a class="btn btn-primary btn-sm shadow" [routerLink]="['/teams', role.team.id]">&lt; Back to {{ role.team.name }}</a></div>
    <div class="item-details border rounded shadow-sm bg-white">
        <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
            <h3 class="p-0">{{ role.name }}</h3>
        </div>
        <div class="p-3">
            <button class="btn btn-warning btn-sm shadow-sm me-1" *ngIf="authService.isAdminForTeam(role.team_id)" (click)="openPopover(p)" #p="ngbPopover" [ngbPopover]="editRoleTemplate" popoverTitle="Rename {{ role.name }}">Rename</button>
            <button class="btn btn-danger btn-sm shadow-sm" *ngIf="authService.isAdminForTeam(role.team_id)" (click)="deleteRole()">Delete</button>
        </div>
        <div class="p-3 border-top">
            <p>
                People:
                <button type="button" class="btn btn-sm btn-success ms-2" placement="right" popoverTitle="Select people" [ngbPopover]="addPeople" *ngIf="authService.isAdminForTeam(role.team_id)">Add people</button>
            </p>
            <table *ngIf="role.people !== null" class="table table-sm">
                <thead>
                    <tr><td>Name</td><td style="text-align: center;">Reserve</td></tr>
                </thead>
                <tbody>
                    <tr *ngFor="let person of role.people">
                        <td>
                            <a href="#" (click)="removePerson(person)" *ngIf="authService.isAdminForTeam(role.team_id)"><app-svg-icon icon="delete"></app-svg-icon></a>&nbsp;
                            <a [routerLink]="['/people', person.id]">{{ person.first_name }} {{ person.last_name }}</a>
                        </td>
                        <td style="text-align: center;">
                            <input type="checkbox" (click)="toggleReserve(person)" [checked]="person.pivot.reserve" [disabled]="!authService.isAdminForTeam(role.team_id)">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #addPeople><app-person-selector (selected)="addPerson($event)" [disallowedPersonIDs]="personIDs" [people]="teamPeople"></app-person-selector></ng-template>

<ng-template #editRoleTemplate>
    <app-edit-role [role]="role" (roleSaved)="roleEdited($event)"></app-edit-role>
</ng-template>
