import { Component, OnInit } from '@angular/core';
import { SubscriptionPromptService } from '../subscription-prompt.service';

@Component({
    selector: 'app-subscription-prompt',
    templateUrl: './subscription-prompt.component.html',
    styleUrls: ['./subscription-prompt.component.css']
})
export class SubscriptionPromptComponent implements OnInit {

    organisationID: string;

    constructor(public service: SubscriptionPromptService) { }

    ngOnInit(): void {
    }

    hide() {
        this.service.visible = false;
    }

}
