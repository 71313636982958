<form class="form" [formGroup]="personForm">
    <div class="mb-3">
        <label class="form-label">Name</label>
        <div class="input-group input-group-sm mb-1">
            <input type="text" class="form-control" #first_name formControlName="first_name" placeholder="First name">
            <input type="text" class="form-control" formControlName="last_name" placeholder="Last name" [ngClass]="{'is-invalid': personForm.controls.last_name.touched && personForm.controls.last_name.errors}">
        </div>
        <div class="invalid-feedback d-block" *ngIf="personForm.controls.last_name.touched && personForm.controls.last_name.errors">Last name is required</div>
    </div>
    <div class="mb-3">
        <label class="form-label">Display name</label>
        <input type="text" class="form-control form-control-sm" formControlName="display_name" placeholder="Display name" [ngClass]="{'is-invalid': personForm.controls.display_name.touched && personForm.controls.display_name.errors}">
        <span class="invalid-feedback" *ngIf="personForm.controls.display_name.touched && personForm.controls.display_name.errors">Display name is required</span>
    </div>
    <div class="mb-3">
        <label class="form-label">Email</label>
        <input type="text" class="form-control form-control-sm" formControlName="email" placeholder="Email" [ngClass]="{'is-invalid': personForm.controls.email.touched && personForm.controls.email.errors}">
        <span class="invalid-feedback" *ngIf="personForm.controls.email.touched && personForm.controls.email.errors">A valid email address is required</span>
    </div>
    <div class="mb-3">
        <label class="form-label">Phone</label>
        <input type="text" class="form-control form-control-sm" formControlName="phone" placeholder="Phone">
    </div>
    <div class="mb-3 form-check" *ngIf="person && person.user_id !== null && authService.isAdmin">
        <input type="checkbox" class="form-check-input" formControlName="is_admin" id="is_admin">
        <label class="form-check-label" for="is_admin">Organisation administrator</label>
    </div>
    <div class="mb-3 form-check" *ngIf="person === undefined && personForm.controls.email.value !== '' && personForm.controls.email.value !== null">
        <input type="checkbox" class="form-check-input" formControlName="send_welcome_email" id="send_welcome_email">
        <label class="form-check-label" for="send_welcome_email">Send welcome email</label>
    </div>
    <button class="btn btn-sm btn-primary" (click)="submit()" [disabled]="personForm.invalid" >Save</button>
</form>
