<div id="topbar" class="d-flex justify-content-between align-items-center flex-wrap shadow-sm">
    <div class="d-flex align-items-center">
        <a class="mx-2" id="menu-toggle" *ngIf="apiService.isAuthenticated" href="#" (click)="sidebar.toggleNav();"><app-svg-icon icon="list"></app-svg-icon></a>
            <span class="ms-1 d-flex flex-nowrap">
                <img id="topbar-logo" src="/assets/logo.svg" alt="plan2gether logo">
                <span class="name text-nowrap ms-1">
                    <a class="home-link" href="https://plan2gether.com" target="_blank">plan<span class="name-second">2gether</span></a>
                    <span *ngIf="environment.name !== 'production'"> | <span class="text-warning">{{ environment.name }}</span></span>
                    | <a class="home-link" [routerLink]="'/rota/'">{{ organisationService.organisationDetails.name }}</a>
                </span>
            </span>
    </div>
    <div class="d-flex align-items-center justify-content-end flex-fill" *ngIf="authService.me !== null">
        <a [routerLink]="['/people', authService.me.id]">{{ authService.me.first_name }} {{ authService.me.last_name }}</a>
        <a class="mx-1" id="topbar-messages" [ngbPopover]="notificationsTemplate">
            <app-svg-icon icon="email" tooltip="Notifications" [hasDot]="hasUnreadMessages"></app-svg-icon>
        </a>
        <a class="btn btn-sm btn-outline-primary me-1" href="#" (click)="logout()">Log out</a>
    </div>
    <div class="d-flex align-items-center" *ngIf="!apiService.isAuthenticated">
        <a class="btn btn-sm btn-outline-primary me-1" [routerLink]="'/login'">Log in</a>
    </div>
</div>

<ng-template #notificationsTemplate><app-notifications-list></app-notifications-list></ng-template>
