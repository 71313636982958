import { Component, OnInit } from '@angular/core';
import { Team } from 'src/app/api/models/team.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamsService } from 'src/app/api/teams.service';
import { Person } from 'src/app/api/models/person.model';
import { RolesService } from 'src/app/api/roles.service';
import { AuthService } from 'src/app/api/auth.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/common-components/toast.service';
import { Role } from 'src/app/api/models/role.model';
import { ConfirmationService } from 'src/app/common-components/confirmation.service';

@Component({
    selector: 'app-team-info',
    templateUrl: './team-info.component.html'
})
export class TeamInfoComponent implements OnInit {

    team: Team;
    personIDs: string[];

    newRoleName = '';
    selectedPersonForAddingRole: Person;

    popover: NgbPopover;

    constructor(
        private route: ActivatedRoute,
        private service: TeamsService,
        private rolesService: RolesService,
        public authService: AuthService,
        private router: Router,
        private toastService: ToastService,
        private confirmationService: ConfirmationService
        ) {
        this.route.params.subscribe(
            params => {
                this.service.getTeam(params['id'])
                    .subscribe(
                        data => {
                            this.team = data;
                            this.personIDs = this.team.people.map( person => person.id );
                        }
                    );
            }
        );
    }

    ngOnInit() {
    }

    openPopover(popover: NgbPopover) {
        this.popover = popover;
    }

    deleteTeam() {
        this.confirmationService.confirm(
            'delete the team ' + this.team.name + '. This will permanently remove it from all events.',
            'Delete',
            answer => {
                if (answer) {
                    this.service.deleteTeam(this.team.id).subscribe(
                        _ => {
                            this.toastService.showSuccess('Team ' + this.team.name + ' deleted.');
                            this.service.updateList();
                            this.router.navigate(['teams']);
                        }
                    );
                }
            }
        );
    }

    addPerson(person: Person) {
        this.service.addPerson(this.team.id, person.id).subscribe(
            team => {
                this.team = team;
                this.personIDs = this.team.people.map( p => p.id );
            }
        );
    }

    removePerson(person: Person) {
        this.service.removePerson(this.team.id, person.id).subscribe(
            team => {
                this.team = team;
                this.personIDs = this.team.people.map( p => p.id);
            }
        );
        return false;
    }

    toggleReserve(person: Person) {
        this.rolesService.updatePerson(this.team.roles[0].id, person.id, !person.roles[0].pivot.reserve).subscribe(
            role => {
                this.team.roles[0] = role;
                person.roles[0].pivot.reserve = !person.roles[0].pivot.reserve;
            }
        );
    }

    toggleAdmin(person: Person) {
        this.service.updatePerson(this.team.id, person.id, !person.pivot.is_admin).subscribe( team => {
            this.team = team;
        });
    }

    openAddRoleToPersonPopover(popover: NgbPopover, person: Person) {
        this.popover = popover;
        this.selectedPersonForAddingRole = person;
    }

    addRoleToPerson(role: Role) {
        if (this.selectedPersonForAddingRole.roles.map(r => r.id).includes(role.id)) {
            this.popover.close();
            return;
        }

        this.rolesService.addPerson(role.id, this.selectedPersonForAddingRole.id).subscribe(
            r => {
                r.pivot = {
                    role_id: r.id,
                    person_id: this.selectedPersonForAddingRole.id,
                    reserve: false
                };
                this.selectedPersonForAddingRole.roles.push(r);
                this.selectedPersonForAddingRole.roles.sort((a,b) => (a.name > b.name) ? 1 : -1);
                this.popover.close();
            }
        )
    }

    addedRole(role: Role) {
        this.team.roles.push(role);
        this.team.roles.sort((a,b) => (a.name > b.name) ? 1 : -1);
        this.popover.close();
    }

    teamEdited(team: Team) {
        this.team = team;
        this.popover.close();
    }
}
