import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'app-svg-icon',
    template: `
        <div class="icon-container">
            <button *ngIf="tooltip != undefined" [ngbTooltip]="tooltip" triggers="hover click" container="body">
                <svg class="icon" style="fill: {{ getColor() }};" [ngStyle]="monochrome ? {'filter': 'grayscale(100%)' } : {}">
                    <use attr.xlink:href="/assets/icons.svg#{{ icon }}" />
                </svg>
            </button>
            <svg *ngIf="tooltip == undefined" class="icon" style="fill: {{ getColor() }};" [ngStyle]="monochrome ? {'filter': 'grayscale(100%)' } : {}">
                <use attr.xlink:href="/assets/icons.svg#{{ icon }}" />
            </svg>
            <span class="dot" *ngIf="hasDot">•</span>
        </div>
    `,
    styles: [
        'button { display: inline; padding: 3px; border: 0; margin: -3px; background: none; }',
        '.icon-container { display: inline-block; position: relative; }',
        'svg { display: inline-block; width: 0.8em; height: 0.8em; vertical-align: baseline; }',
        '.dot { position: absolute; top: -0.2em; right: -0.2em; font-size: 0.8em; color: red; } '
    ]
})
export class SvgIconComponent {
    @Input() icon: string;
    @Input() monochrome = false;
    @Input() color = 'black';
    @Input() tooltip?: string;
    @Input() hasDot = false;

    getColor() {
        if (this.color === 'primary') {
            return '#0d6efd';
        } else if (this.color === 'success') {
            return '#198754';
        } else if (this.color === 'warning') {
            return '#ffc107';
        } else if (this.color === 'danger') {
            return '#dc3545';
        } else if (this.color === 'info') {
            return '#2784D8';
        } else {
            return this.color;
        }
    }
}
