<div class="d-flex align-items-center justify-content-center">
    <div class="border rounded bg-white shadow-sm login-card">
        <div class="border-bottom px-3 pt-3 pb-1">
            <h3 class="p-0">Log in to {{ organisationService.organisationDetails.name }}</h3>
        </div>
        <div class="p-3">
            <form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="mb-3">
                    <label class="form-label">Email:</label>
                    <input class="form-control" [ngClass]="{'is-invalid': loginForm.controls.email.touched && loginForm.controls.email.errors}" type="email" id="email" formControlName="email">
                    <span class="invalid-feedback" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors">Email address is invalid</span>
                </div>
                <div class="mb-3">
                    <label class="form-label">Password:</label>
                    <input type="password" [ngClass]="{'is-invalid': loginForm.controls.password.touched && loginForm.controls.password.errors}" formControlName="password" class="form-control">
                    <span class="invalid-feedback" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors">Password is required</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <a href="https://plan2gether.com/login-help">Trouble logging in? Click here to reset your password</a>
                    <button class="btn btn-primary mx-3" type="submit" [disabled]="loginForm.invalid">Log&nbsp;in</button>
                </div>
            </form>
        </div>
    </div>
</div>
