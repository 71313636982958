import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiModule } from './api.module';
import { ApiService } from './api.service';
import { Notification } from './models/notification.model';

@Injectable({
    providedIn: ApiModule
})
export class NotificationsService {

    unreadCount: number = 0;
    notifications: Notification[] = [];
    unreadCountUpdated = new EventEmitter<number>();
    notificationsUpdated = new EventEmitter<Notification[]>();

    constructor(private api: ApiService) { }

    updateNotifications() {
        this.api.get<number>('notifications/unread-count').subscribe(count => {
            this.unreadCount = count;
            this.unreadCountUpdated.emit(count);
        });
        this.api.get<Notification[]>('notifications').subscribe(notifications => {
            this.notifications = notifications;
            this.notificationsUpdated.emit(notifications);
        })
    }

    markAsRead(notificationID: string) {
        this.api.post<Notification>('notifications/' + notificationID + '/read', {}).subscribe(_ => {
            this.updateNotifications();
        });
    }
}
