import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { ToastsComponent } from './app-toasts/toasts.component';
import { NgbToastModule, NgbTooltipModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { FormsModule } from '@angular/forms';
import { AlphanumericPipe } from './alphanumeric.pipe';
import { HintComponent } from './hint/hint.component';
import { SubscriptionPromptComponent } from './subscription-prompt/subscription-prompt.component';
import { RouterModule } from '@angular/router';
import { HasFlagPipe } from './has-flag.pipe';



@NgModule({
  declarations: [
    SvgIconComponent,
    ToastsComponent,
    ConfirmationComponent,
    TimePickerComponent,
    AlphanumericPipe,
    HintComponent,
    SubscriptionPromptComponent,
    HasFlagPipe
  ],
  imports: [
    CommonModule,
    NgbPopoverModule,
    NgbToastModule,
    NgbTooltipModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    SvgIconComponent,
    ToastsComponent,
    ConfirmationComponent,
    TimePickerComponent,
    AlphanumericPipe,
    HintComponent,
    SubscriptionPromptComponent,
    HasFlagPipe
  ]
})
export class CommonComponentsModule { }
