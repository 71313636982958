import { Injectable, EventEmitter } from '@angular/core';
import { ApiModule } from './api.module';
import { EventTemplate } from './models/eventtemplate.model';
import { Observable, observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: ApiModule
})
export class EventTemplatesService {

    constructor(private api: ApiService) { }

    eventTemplates?: EventTemplate[];

    eventTemplatesUpdated = new EventEmitter<EventTemplate[]>();

    updateList() {
        this.eventTemplates = null;
        this.api.get<EventTemplate[]>('event-templates')
            .subscribe(data => {
                this.eventTemplates = data.map(value => Object.assign(new EventTemplate(), value));
                this.eventTemplatesUpdated.emit(this.eventTemplates);
            }, error => {
                this.eventTemplatesUpdated.emit([]);
            });
    }

    getTemplate(id: string) {
        return this.api.get<EventTemplate>('event-templates/' + id);
    }

    addTemplate(template: EventTemplate) {
        return new Observable<EventTemplate>(subscriber => {
            return this.api.post<EventTemplate>('event-templates', template)
                .subscribe(data => {
                    this.updateList();
                    subscriber.next(Object.assign(new EventTemplate(), data));
                    subscriber.complete();
                }, error => {
                    subscriber.next(null);
                    subscriber.complete();
                });
        });
    }

    updateTemplate(template: EventTemplate) {
        return new Observable<EventTemplate>(subscriber => {
            return this.api.put<EventTemplate>('event-templates/' + template.id, template)
                .subscribe(data => {
                    this.updateList();
                    subscriber.next(Object.assign(new EventTemplate(), data));
                    subscriber.complete();
                }, error => {
                    subscriber.next(null);
                    subscriber.complete();
                });
        });
    }

    deleteTemplate(templateID: string) {
        return this.api.delete('event-templates/' + templateID);
    }

    addTeam(templateID: string, teamID: string) {
        return new Observable<EventTemplate>(subscriber => {
            this.api.post<EventTemplate>('event-templates/' + templateID + '/teams', {id: teamID})
                .subscribe(data => {
                    subscriber.next(Object.assign(new EventTemplate(), data));
                    subscriber.complete();
                });
        });
    }

    deleteTeam(templateID: string, teamID: string) {
        return new Observable<EventTemplate>(subscriber => {
            this.api.delete<EventTemplate>('event-templates/' + templateID + '/teams/' + teamID).subscribe( data => {
                subscriber.next(Object.assign(new EventTemplate(), data));
                subscriber.complete();
            });
        });
    }

// tslint:disable:variable-name
    updateRole(templateID: string, roleID: string, autogenerate_people_count: number) {
        return new Observable<EventTemplate>(subscriber => {
            this.api.post<EventTemplate>('event-templates/' + templateID + '/roles', {
                id: roleID,
                autogenerate_people_count
            }).subscribe(data => {
                subscriber.next(Object.assign(new EventTemplate(), data));
                subscriber.complete();
            });
        });
    }
// tslint:enable:variable-name

    recreateRota(templateID: string) {
        return new Observable<EventTemplate>(subscriber => {
            this.api.post<EventTemplate>('event-templates/' + templateID + '/recreate-rota', {}).subscribe( data => {
                subscriber.next(Object.assign(new EventTemplate(), data));
                subscriber.complete();
            });
        });
    }

    createEvents(templateID: string, untilDate: string) {
        return new Observable<EventTemplate>(subscriber => {
            this.api.post<EventTemplate>('event-templates/' + templateID + '/create-events', {until_date: untilDate}).subscribe( data => {
                subscriber.next(Object.assign(new EventTemplate(), data));
                subscriber.complete();
            });
        });
    }

    recreateRotaForTeam(templateID: string, teamID: string) {
        return new Observable<boolean>(subscriber => {
            this.api.post('event-templates/' + templateID + '/teams/' + teamID + '/recreate-rota', {}).subscribe( data => {
                subscriber.next(true);
                subscriber.complete();
            });
        });
    }

    updateDefaultNotes(templateID: string, notes: string) {
        return this.api.post('event-templates/' + templateID + '/default-notes', {notes});
    }

    updateTeamDefaultNotes(templateID: string, teamID: string, notes: string) {
        return this.api.post('event-templates/' + templateID + '/teams/' + teamID + '/default-notes', {notes});
    }
}
