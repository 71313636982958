import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'alphanumeric'
})
export class AlphanumericPipe implements PipeTransform {

    transform(value: string): string {
        return value.replace(/[^a-zA-Z0-9]/g, '');
    }

}
