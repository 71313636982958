import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Event, EventTeamRole, EventTeam } from 'src/app/api/models/event.model';
import { AuthService } from 'src/app/api/auth.service';
import { Role } from 'src/app/api/models/role.model';
import { EventsService } from 'src/app/api/events.service';
import { ToastService } from 'src/app/common-components/toast.service';
import { ConfirmationService } from 'src/app/common-components/confirmation.service';
import { sortPeople } from 'src/app/api/models/person.model';
import { ApiService } from 'src/app/api/api.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventUpdatesService } from 'src/app/websockets/event-updates.service';

@Component({
    selector: 'app-rota-event',
    templateUrl: './rota-event.component.html',
    styleUrl: './rota-event.component.css'
})
export class RotaEventComponent implements OnInit {

    @Input() event: Event;
    @Input() filteredTeams: string[];
    @Input() inList = true;
    @Output() deleted = new EventEmitter<Event>();

    editing = false;

    selectedNotesTeam?: EventTeam = null;
    selectedNotes = '';
    selectedNotesEditable = false;

    constructor(
        public api: ApiService,
        private service: EventsService,
        public authService: AuthService,
        private toastService: ToastService,
        private confirmationService: ConfirmationService,
        private modal: NgbModal,
        private eventUpdates: EventUpdatesService
        ) { }

    ngOnInit(): void {

        this.eventUpdates.eventUpdated.subscribe(event => {
            if (event.id === this.event.id) {
                this.event = event;
            }
        });

        this.eventUpdates.eventDeleted.subscribe(eventID => {
            if (eventID === this.event.id) {
                this.deleted.emit(this.event);
            }
        });
    }

    peopleForEventRole(eventTeamRole: EventTeamRole) {
        return this.event.people
            .filter(p => p.event_team_role_id === eventTeamRole.id)
            .sort((a, b) => { return sortPeople(a.person, b.person); });
    }

    peopleForRole(role: Role) {
        const eventTeam = this.event.teams.find(et => et.team_id === role.team_id);
        if (eventTeam === undefined) return [];

        const eventTeamRole = eventTeam.roles.find(r => r.role_id === role.id);
        if (eventTeamRole === undefined) return [];

        return this.event.people.filter(p => p.event_team_role_id === eventTeamRole.id);
    }

    closeModal() {
        this.modal.dismissAll();
    }

    toggleEditing() {
        this.editing = !this.editing;
    }

    canEditEvent() {
        if (!this.api.isAuthenticated || this.authService.me === null) {
            return false;
        }

        if (this.event.teams === undefined || this.event.teams.length === 0) {
            return false;
        }

        const personRoles = this.authService.me.roles.map(r => r.id);
        return this.event.teams.filter(t => this.authService.isAdminForTeam(t.team_id)).length > 0
            || this.event.teams.filter(t => t.team.roles.filter(r => personRoles.includes(r.id)).length > 0).length > 0;
    }

    canEditRole(role: Role) {
        return this.authService.isAdminForTeam(role.team_id)
            || this.authService.me.roles.filter(r => r.id === role.id).length === 1
    }

    changed(event: Event) {
        this.event = event;
    }

    deleteEvent() {
        this.confirmationService.confirm(
            'delete the event instance ' + this.event.name + ' at ' + moment(this.event.starts_at).format('D MMM HH:mm'),
            'Delete',
            answer => {
                if (answer) {
                    this.service.deleteEvent(this.event.id).subscribe( _ => {
                        this.toastService.showSuccess('Event ' + this.event.name + ' at ' + moment(this.event.starts_at).format('D MMM HH:mm') + ' deleted.');
                        this.deleted.emit(this.event);
                    });
                }
            });
    }

    editEventNotes() {
        this.selectedNotesTeam = null;
        this.selectedNotes = this.event.notes;
        this.selectedNotesEditable = this.authService.isAdmin;
    }

    editTeamNotes(team: EventTeam) {
        this.selectedNotesTeam = team;
        this.selectedNotes = team.notes;
        this.selectedNotesEditable = this.authService.isAdminForTeam(team.team_id);
    }

    notesChanged(notes: string) {
        if (!this.selectedNotesEditable) {
            return;
        }

        if (this.selectedNotesTeam === null) {
            this.event.notes = notes;
        } else {
            this.selectedNotesTeam.notes = notes;
        }
    }
}
