import { Component, OnInit } from '@angular/core';
import { HintService } from '../hint.service';

@Component({
    selector: 'app-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.css']
})
export class HintComponent implements OnInit {

    constructor(public service: HintService) { }

    ngOnInit(): void {
    }

}
