import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiModule } from './api.module';
import { Person } from './models/person.model';
import { ToastService } from '../common-components/toast.service';
import { Router } from '@angular/router';
import { LoginRedirect } from './models/loginredirect.model';
import * as platform from 'platform';
import { NotificationsService } from './notifications.service';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: ApiModule
})
export class AuthService {

    me?: Person = null;

    get isAdmin() {
        return this.me !== null && this.me.is_admin
    };

    adminTeams: string[] = [];

    constructor(
        private api: ApiService,
        private notificationsService: NotificationsService,
        private toastService: ToastService,
        private router: Router
    ) {
        if (window.location.search.includes('loginid=')) {
            const loginID = window.location.search.substr(window.location.search.indexOf('loginid') + 8, 36);

            if (api.isAuthenticated) {
                this.api.post('auth/logout', {}).subscribe(_ => {
                    this.redirectLogin(loginID);
                });
                this.didLogout();
            } else {
                this.redirectLogin(loginID);
            }
        } else if (api.isAuthenticated) {
            this.api.get<Person>('people/me').subscribe(person => {
                this.me = person;
                this.adminTeams = this.me.teams.filter( team => team.pivot.is_admin ).map( team => team.id );
                this.notificationsService.updateNotifications();
            });
        }
    }

    private deviceName() {
        return '' + window.location.hostname + ' (' + platform.name + ' on ' + platform.os + ')';
    }

    isAdminForTeam(teamID: string) {
        return this.isAdmin || this.adminTeams.includes(teamID);
    }

    login(email, password) {
        const subdomain = window.location.hostname.split('.')[0];
        this.api.post<{token: string, person: Person}>('auth/login', {email, password, organisation: subdomain, device_name: this.deviceName()}, false)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.toastService.showError('Invalid username or password');
                    return throwError(error);
                })
            )
            .subscribe(data => {
                this.didLogin(data.token, data.person);
            });
    }

    redirectLogin(loginID) {
        const subdomain = window.location.hostname.split('.')[0];
        this.api.post<{token: string, person: Person}>('auth/redirect', {login_id: loginID, organisation: subdomain, device_name: this.deviceName()}, false).subscribe(data => {
            this.didLogin(data.token, data.person);
        });
    }

    private didLogin(token: string, person: Person) {
        this.toastService.showSuccess('Logged in as ' + person.first_name + ' ' + person.last_name);
        this.api.isAuthenticated = true;
        localStorage.setItem('api-token', token)
        this.me = person;
        this.adminTeams = this.me.teams.filter( team => team.pivot.is_admin ).map( team => team.id );
        this.notificationsService.updateNotifications();
        this.router.navigateByUrl('/rota');
    }

    redirectToken() {
        return this.api.get<LoginRedirect>('login-redirect-token');
    }

    logout() {
        this.api.post('auth/logout', {}).subscribe(_ => {
            this.didLogout();
            this.router.navigateByUrl('/rota');
        });
    }

    private didLogout() {
        this.me = null;
        this.adminTeams = [];
        localStorage.removeItem('api-token');
        this.api.isAuthenticated = false;
    }
}
