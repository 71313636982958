import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { catchError, first, from, map, Observable, of, switchMap, timeout, timer } from 'rxjs';
import { ToastService } from '../common-components/toast.service';

@Injectable({
    providedIn: 'root'
})
export class PwaServiceService {

    constructor(
        private appRef: ApplicationRef,
        private updateService: SwUpdate,
        private toastService: ToastService
    ) {
        if (this.updateService.isEnabled) {
            this.appRef.isStable.pipe(
                first(isStable => isStable === true),
                switchMap(() => this.updateService.versionUpdates),
            ).subscribe((event) => {
                if (event.type == 'VERSION_READY') {
                    this.toastService.show({body: 'Update available - please wait...', class: 'bg-info text-light', autohide: false});
                    this.updateService.activateUpdate().then(() => document.location.reload());
                } else if (event.type == 'VERSION_INSTALLATION_FAILED') {
                    this.toastService.showError('Unable to update automatically - please refresh the page');
                }
            });

            timer(60000, 60000).pipe(
                switchMap(() => this.updateService.checkForUpdate())
            ).subscribe(updateAvailable => {
                if (updateAvailable) {
                    console.log('Update available - activating');
                    this.toastService.show({body: 'Update available - please wait...', class: 'bg-info text-light', autohide: false});
                    this.updateService.activateUpdate().then(() => document.location.reload());
                } else {
                    console.log('No update found');
                }
            });
        }
    }

    checkForUpdate(): Observable<boolean> {
        const waitFor = 1000;

        console.log('Checking for updates...');

        if (this.updateService.isEnabled) {
            const available$ = this.updateService.versionUpdates.pipe(
                first(event => event.type == 'VERSION_DETECTED'),
                map(() => true),
                timeout(waitFor),
                catchError(() => of(false)),
            );

            return from(this.updateService.checkForUpdate()).pipe(
                switchMap(() => available$),
            );
        }

        return timer(waitFor).pipe(map(() => false));
    }
}
