import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RotaPageComponent } from './rota-page/rota-page.component';
import { RotaPersonComponent } from './rota-person/rota-person.component';
import { ApiModule } from '../api/api.module';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { NgbDatepickerModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RotaEventComponent } from './rota-event/rota-event.component';
import { RotaCalendarComponent } from './rota-calendar/rota-calendar.component';
import { NotesComponent } from './notes/notes.component';
import { FormsModule } from '@angular/forms';
import { WebsocketsModule } from '../websockets/websockets.module';
import { RotaPasscodeEntryComponent } from './rota-passcode-entry/rota-passcode-entry.component';
import { RotaGridComponent } from './rota-grid/rota-grid.component';
import { RotaDatePickerComponent } from './rota-date-picker/rota-date-picker.component';
import { PersonSelectorModule } from '../person-selector/person-selector.module';



@NgModule({
    declarations: [RotaPageComponent, RotaPersonComponent, RotaEventComponent, RotaCalendarComponent, NotesComponent, RotaPasscodeEntryComponent, RotaGridComponent, RotaDatePickerComponent],
    imports: [
        CommonModule, ApiModule, CommonComponentsModule, PersonSelectorModule, NgbPopoverModule, FormsModule, NgbDatepickerModule, WebsocketsModule
    ],
    exports: [
        RotaPageComponent, RotaEventComponent
    ]
})
export class RotaModule { }
