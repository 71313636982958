export const environment = {
    production: true,
    name: 'staging',
    apiBaseURL: '//plan2gether-staging.com/api/v1/',
    websocketAppKey: 'z3qt3qhCPI9CjJ3f',
    websocketHost: 'ws.acsv.cc',
    websocketPort: 443,
    websocketAuthURL: '//plan2gether-staging.com/laravel-websockets/auth',
    websocketTLS: true,
    loginRedirectURL: '//plan2gether-staging.com/login/redirect/'
};
