import { Component, OnInit, Input } from '@angular/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { AuthService } from '../api/auth.service';
import { OrganisationService } from '../api/organisation.service';
import { ApiService } from '../api/api.service';
import { NotificationsService } from '../api/notifications.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

    environment = environment;

    @Input() sidebar: SidebarComponent;

    hasUnreadMessages = false;

    constructor(
        public apiService: ApiService,
        public authService: AuthService,
        public organisationService: OrganisationService,
        private notificationsService: NotificationsService
    ) { }

    ngOnInit(): void {
        this.notificationsService.unreadCountUpdated.subscribe(count => {
            this.hasUnreadMessages = (count > 0);
        });
    }

    logout() {
        this.authService.logout();
        return false;
    }

}
