import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { OrganisationService } from 'src/app/api/organisation.service';
import { PwaServiceService } from '../pwa-service.service';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.css'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('fadeOut', [
            transition(':leave', [
                query(':leave', animateChild(), {optional: true}),
                animate(300, style({opacity: 0}))
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashScreenComponent implements OnInit {

    waitingForUpdates = true;
    waitingForOrganisation = true;
    validOrganisation = false;

    subtext = 'Please wait...';

    constructor(
        private pwaService: PwaServiceService,
        private cdr: ChangeDetectorRef,
        private organisationService: OrganisationService
    ) { }

    ngOnInit(): void {
        this.checkForUpdate();
        this.checkOrganisation();
    }

    checkForUpdate() {
        this.pwaService.checkForUpdate()
            .subscribe(result => {
                this.waitingForUpdates = result;
                this.cdr.detectChanges();
            });
    }

    checkOrganisation() {
        this.organisationService.getOrganisationDetails()
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.subtext = 'Organisation not found';
                    this.waitingForOrganisation = false;
                    this.cdr.detectChanges();
                    return throwError(error);
                })
            )
            .subscribe(_ => {
                this.waitingForOrganisation = false;
                this.validOrganisation = true;
                this.cdr.detectChanges();
            });
    }

}
