import { Role } from './role.model';
import { Team } from './team.model';

// tslint:disable:variable-name
export class Person {
    id?: string;
    user_id?: string;
    organisation_id?: string;
    first_name?: string;
    last_name?: string;
    display_name: string;
    email?: string;
    has_email?: boolean;
    phone?: string;
    has_phone?: boolean;
    is_admin?: boolean;
    welcome_email_sent_at?: string;
    created_at?: string;
    updated_at?: string;
    roles?: Role[];
    teams?: Team[];

    pivot?: RolePerson & TeamPerson;
}
// tslint:enable:variable-name

export interface RolePerson {
    role_id: string;
    person_id: string;
    reserve: boolean;
}

export interface TeamPerson {
    team_id: string;
    person_id: string;
    is_admin: boolean;
}

export function sortPeople(a: Person, b: Person) {
    let aFirst: string;
    let aLast: string;
    let bFirst: string;
    let bLast: string;

    if (a.last_name !== undefined && a.first_name !== undefined && b.last_name !== undefined && b.first_name !== undefined) {
        aFirst = a.first_name;
        aLast = a.last_name;
        bFirst = b.first_name;
        bLast = b.last_name;
    } else {
        if (a.display_name.includes(' ')) {
            const components = a.display_name.split(' ');
            aLast = components.pop();
            aFirst = components.join(' ');
        } else {
            aFirst = a.display_name;
            aLast = a.display_name;
        }
        if (b.display_name.includes(' ')) {
            const components = b.display_name.split(' ');
            bLast = components.pop();
            bFirst = components.join(' ');
        } else {
            bFirst = b.display_name;
            bLast = b.display_name;
        }
    }

    if (aLast > bLast) {
        return 1;
    } else if (aLast < bLast) {
        return -1;
    } else if (aFirst > bFirst) {
        return 1;
    } else if (aFirst < bFirst) {
        return -1;
    } else {
        return 0;
    }
}