import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeoplePageComponent } from './people-page/people-page.component';
import { PersonInfoComponent } from './person-info/person-info.component';
import { EditPersonComponent } from './edit-person/edit-person.component';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PeopleUploaderComponent } from './people-uploader/people-uploader.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { RotaModule } from '../rota/rota.module';



@NgModule({
    declarations: [PeoplePageComponent, PersonInfoComponent, EditPersonComponent, PeopleUploaderComponent],
    imports: [
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        NgbPopoverModule,
        CommonComponentsModule,
        RotaModule
    ]
})
export class PeopleModule { }
