import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

enum DateRange {
    upcoming = 'upcoming',
    last28 = 'last28',
    custom = 'custom'
}

@Component({
    selector: 'app-rota-date-picker',
    templateUrl: './rota-date-picker.component.html'
})
export class RotaDatePickerComponent implements OnInit {

    @Output() dateRangeChanged = new EventEmitter<{start?: string, end?: string}>();

    DateRange = DateRange;
    selectedDateRange = DateRange.upcoming;
    startDate = {year: 0, month: 0, day: 0};
    endDate = {year: 0, month: 0, day: 0};

    constructor() { }

    ngOnInit(): void {
        const today = moment();
        this.startDate = {year: today.year(), month: today.month() + 1, day: today.date()};

        const sessionSelectedRange = sessionStorage.getItem('selectedDateRange');

        if (sessionSelectedRange !== null) {
            this.selectedDateRange = DateRange[sessionSelectedRange];

            if (this.selectedDateRange === DateRange.custom) {
                const sessionStart = sessionStorage.getItem('startDate');

                if (sessionStart !== null) {
                    this.startDate = {
                        year: parseInt(sessionStart.substring(0, 4), 10),
                        month: parseInt(sessionStart.substring(5, 7), 10) - 1,
                        day: parseInt(sessionStart.substring(8, 10), 10)
                    };
                }

                const sessionEnd = sessionStorage.getItem('endDate');

                if (sessionEnd !== null) {
                    this.endDate = {
                        year: parseInt(sessionEnd.substring(0, 4), 10),
                        month: parseInt(sessionEnd.substring(5, 7), 10) - 1,
                        day: parseInt(sessionEnd.substring(8, 10), 10)
                    };
                }
            }

            this.changedSelection();
        }
    }

    datesAreValid() {
        if (
            this.startDate.year > this.endDate.year
            || this.startDate.year === this.endDate.year && this.startDate.month > this.endDate.month
            || this.startDate.year === this.endDate.year && this.startDate.month === this.endDate.month && this.startDate.day > this.endDate.day
        ) {
            return false;
        }

        return true;
    }

    updateStartDate() {
        if (!this.datesAreValid()) {
            this.endDate = this.startDate;
        }

        this.emitSelection();
    }

    updateEndDate() {
        if (!this.datesAreValid()) {
            this.startDate = this.endDate;
        }

        this.emitSelection();
    }

    changedSelection() {
        const today = moment();
        switch (this.selectedDateRange) {
            case DateRange.upcoming:
                this.startDate = {year: today.year(), month: today.month() + 1, day: today.date()};
                this.endDate = {year: 0, month: 0, day: 0};
                break;
            case DateRange.last28:
                const start = moment().subtract(28, 'days');
                this.startDate = {year: start.year(), month: start.month() + 1, day: start.date()};
                this.endDate = {year: today.year(), month: today.month() + 1, day: today.date()};
                break;
            default:
                break;
        }

        this.emitSelection();
    }

    emitSelection() {
        sessionStorage.setItem('selectedDateRange', DateRange[this.selectedDateRange]);

        if (this.selectedDateRange === DateRange.upcoming) {
            this.dateRangeChanged.emit({start: null, end: null});
            sessionStorage.removeItem('startDate');
            sessionStorage.removeItem('endDate');
            return;
        }

        const startString = '' + this.startDate.year + '-'
        + (this.startDate.month < 10 ? '0' : '') + this.startDate.month + '-'
        + (this.startDate.day < 10 ? '0' : '') + this.startDate.day;

        let endString = '' + this.endDate.year + '-'
        + (this.endDate.month < 10 ? '0' : '') + this.endDate.month + '-'
        + (this.endDate.day < 10 ? '0' : '') + this.endDate.day;

        if (this.endDate.year === 0) {
            endString = null;
        }

        this.dateRangeChanged.emit({start: startString, end: endString});

        sessionStorage.setItem('startDate', startString);

        if (endString === null) {
            sessionStorage.removeItem('endDate');
        } else {
            sessionStorage.setItem('endDate', endString);
        }
    }

}
