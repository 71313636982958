import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../common-components/toast.service';
import { Injectable } from '@angular/core';
import { SubscriptionPromptService } from '../common-components/subscription-prompt.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private toastService: ToastService, private subscriptionService: SubscriptionPromptService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    localStorage.removeItem('api-token');
                    return throwError(error);
                }
                if (error.status === 402) {
                    this.subscriptionService.show();
                    return throwError(error);
                }
                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = 'Client Error: ' + error.error.message;
                } else {
                    // server-side error
                    if (error.error.message !== undefined) {
                        errorMessage =
                            'Error: "'
                            + error.error.message
                            + '" in file: '
                            + error.error.file
                            + ' at line: '
                            + error.error.line;
                    } else if (error.error.validation_error) {
                        Object.keys(error.error.validation_error).forEach(key => {
                            this.toastService.showError(error.error.validation_error[key]);
                        });
                        return;
                    } else {
                        errorMessage = 'Error: ' + (error.error.error ?? 'Server error');
                    }
                }
                this.toastService.showError(errorMessage);
                return throwError(error);
            })
        );
    }
}
