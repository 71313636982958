import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventTemplate } from 'src/app/api/models/eventtemplate.model';
import * as moment from 'moment';
import { EventsService } from 'src/app/api/events.service';
import { AddingEvent, Event } from 'src/app/api/models/event.model';

@Component({
    selector: 'app-edit-event',
    templateUrl: './edit-event.component.html'
})
export class EditEventComponent implements OnInit {

    @Input() template: EventTemplate;
    @Input() event: Event;
    @Output() saved = new EventEmitter<Event>();
    @Output() cancelled = new EventEmitter();

    formError: string;

    name: string;
    date = {year: 0, month: 0, day: 0};
    startTime: string;
    endTime: string;

    constructor(private service: EventsService) { }

    ngOnInit(): void {
        if (this.event !== undefined) {
            this.name = this.event.name;
            const startsAt = moment(this.event.starts_at);
            this.date = {year: startsAt.year(), month: startsAt.month()+1, day: startsAt.date()};
            const times = this.event.display_times.split(' - ');
            this.startTime = times[0];
            this.endTime = times[1];
        } else {
            this.name = this.template.name;
            const today = moment();
            this.date = {year: today.year(), month: today.month()+1, day: today.date()};

            const times = this.template.display_times.split(' - ');
            this.startTime = times[0];
            this.endTime = times[1];
        }
    }

    timeUpdated(which: string, time: string) {
        if (which === 'start') {
            this.startTime = time;
        } else {
            this.endTime = time;
        }
    }

    save() {
        this.formError = '';

        const regex = /^[0-9][0-9]:[0-9][0-9]$/;

        if (!regex.test(this.startTime)) {
            this.formError = 'Invalid start time';
            return;
        }

        if (!regex.test(this.endTime)) {
            this.formError = 'Invalid end time';
            return;
        }

        const startComponents = this.startTime.split(':').map(str => Number(str));
        const startTime = startComponents[0] * 60 + startComponents[1];

        const endComponents = this.endTime.split(':').map(str => Number(str));
        const endTime = endComponents[0] * 60 + endComponents[1];

        if (endTime < startTime) {
            this.formError = 'End time must be after start time';
            return;
        }

        const event: AddingEvent = {
            name: this.name,
            event_template_id: this.template.id,
            date: '' + this.date.year + '-'
                + (this.date.month < 10 ? '0' : '') + this.date.month + '-'
                + (this.date.day < 10 ? '0' : '') + this.date.day,
            start_time: startTime,
            end_time: endTime
        };

        if (this.event === undefined) {
            this.service.addEvent(event).subscribe(
                result => {
                    if (result !== null) {
                        this.saved.emit(result);
                    }
                }
            );
        } else {
            this.service.updateEvent(this.event.id, event).subscribe(
                result => {
                    if (result !== null) {
                        this.saved.emit(result);
                    }
                }
            );
        }
    }

    cancel() {
        this.cancelled.emit();
    }

}
