<div class="d-flex align-items-start h-100">
    <div class="items-list flex-column" [ngClass]="{'d-none d-sm-block': selectedTemplateID !== null}">
        <div class="d-flex flex-wrap align-items-center mb-3" *ngIf="authService.isAdmin">
            <button class="btn btn-success btn-sm shadow me-2" (click)="addTemplate(p)" #p="ngbPopover" [ngbPopover]="addEventTemplate" [autoClose]="false">
                <app-svg-icon icon="add" color="white"></app-svg-icon>
                Add new
            </button>
            <a class="btn btn-info btn-sm shadow" href="https://support.plan2gether.com/category/events" target="_blank"><app-svg-icon icon="help" color="white"></app-svg-icon> Help</a>
        </div>

        <div class="border rounded-top shadow-sm bg-white">
            <div class="border-bottom rounded-top p-1 bg-light">
                <span>{{ templates.length }} event templates</span>
            </div>
            <ul class="list-group list-group-flush">
                <button type="button" (click)="selectTemplate(template)" class="list-group-item list-group-item-action py-1 ps-3 pe-2" [ngClass]="{'bg-medium': template.id === selectedTemplateID}" *ngFor="let template of templates">
                    <div class="d-flex justify-content-between align-items-start">
                        <span>{{ template.name }}</span>
                        <small class="text-end">
                            <span *ngIf="template.recurring">{{ template.display_days }} <span class="text-nowrap">{{ template.display_times }}</span><br></span>
                            <span *ngIf="template.events.length > 0 && template.recurring">Next: {{ template.events[0].starts_at | date:"EEE, dd MMM y" }}</span>
                            <span *ngIf="template.events.length > 0 && !template.recurring">Latest: {{ template.events[0].starts_at | date:"EEE, dd MMM y HH:mm" }}</span>
                        </small>
                    </div>
                </button>
            </ul>
        </div>
    </div>
    <div class="ps-sm-2 item-details sticky-top" *ngIf="selectedTemplateID !== null">
        <div class="d-sm-none mb-3"><a class="btn btn-primary btn-sm shadow" [routerLink]="['/events']">&lt; Back to all events</a></div>
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #addEventTemplate>
    <app-edit-template (templateSaved)="addedTemplate($event)" (cancelled)="cancelAddingTemplate()"></app-edit-template>
</ng-template>
