<div id="container" aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center" *ngIf="service.visible" (click)="hide()">
    <ngb-toast header="Upgrade your organisation" [autohide]="false" (hidden)="hide()">
        <p>Take out a paid subscription for your organisation to lift the following limits:</p>
        <div class="card-group">
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">Free</h3>
                    <ul>
                        <li>300 people</li>
                        <li>1 event</li>
                        <li>1 team of up to 20 people</li>
                        <li>10 roles</li>
                        <li>Email messaging</li>
                        <li>SMS rota reminders</li>
                        <li>Limited support</li>
                    </ul>
                </div>
                <div class="card-footer text-center">
                    <button class="btn btn-outline-secondary" (click)="hide()">Continue using free account</button>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">£10 per month</h3>
                    <ul>
                        <li>Unlimited people*</li>
                        <li>Unlimited events</li>
                        <li>Unlimited teams and roles</li>
                        <li>Email messaging</li>
                        <li>SMS rota reminders</li>
                        <li>50 free SMS credits</li>
                        <li>Priority support</li>
                    </ul>
                    <small>* Requires validation of your organisation</small>
                </div>
                <div class="card-footer text-center">
                    <a class="btn btn-outline-primary" [routerLink]="['/organisation']" fragment="subscribe" target="_blank">Subscribe</a>
                </div>
            </div>
        </div>
    </ngb-toast>
</div>
