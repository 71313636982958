import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';
import { WebsocketsModule } from './websockets.module';

@Injectable({
    providedIn: WebsocketsModule
})
export class ClientService {

    private pusherClient: Pusher;

    constructor() {
        Pusher.logToConsole = true;
        this.pusherClient = new Pusher(environment.websocketAppKey, {
            cluster: 'mt1',
            wsHost: environment.websocketHost,
            wsPort: environment.websocketPort,
            wssPort: environment.websocketPort,
            authEndpoint: environment.websocketAuthURL,
            enableStats: false,
            enabledTransports: ['ws'],
            forceTLS: environment.websocketTLS
        });
    }

    public subscribe(channel: string, event: string, callback: Function) {
        this.pusherClient.subscribe(channel).bind(event, callback);
    }
}
