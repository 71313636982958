import { Injectable } from '@angular/core';
import { ApiModule } from './api.module';
import { Organisation, OrganisationDetails, OrganisationPreferences } from './models/organisation.model';
import { ApiService } from './api.service';

@Injectable({
    providedIn: ApiModule
})
export class OrganisationService {

    organisationDetails: OrganisationDetails = {
        id: '',
        name: ''
    };

    constructor(private api: ApiService) {
        this.getOrganisationDetails().subscribe(details => { this.organisationDetails = details; });
    }

    getOrganisationDetails() {
        return this.api.get<OrganisationDetails>('organisation/' + window.location.hostname.split('.')[0], false);
    }

    getOrganisation() {
        return this.api.get<Organisation>('organisation');
    }

    updatePreferences(preferences: OrganisationPreferences) {
        return this.api.post<Organisation>('organisation/preferences', preferences);
    }
}
