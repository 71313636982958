<div class="header-buttons d-flex flex-wrap align-items-center" *ngIf="!errorPasscodeRequired">
    <button class="btn btn-primary btn-sm shadow me-2 mb-3" (click)="openViewPopover(p)" #p="ngbPopover" [ngbPopover]="viewSelector">View <app-svg-icon icon="down-arrow" color="white"></app-svg-icon></button>
    <button class="btn btn-primary btn-sm shadow me-2 mb-3" [ngClass]="{'active': startDate !== null}" [ngbPopover]="dateSelector"><app-svg-icon class="me-2" icon="filter" color="white" *ngIf="startDate !== null"></app-svg-icon>Dates <app-svg-icon icon="down-arrow" color="white"></app-svg-icon></button>
    <button class="btn btn-primary btn-sm shadow me-2 mb-3" [ngClass]="{'active': filteredTeamIDs.length > 0}" [ngbPopover]="teamSelector"><app-svg-icon class="me-2" icon="filter" color="white" *ngIf="filteredTeamIDs.length > 0"></app-svg-icon>Teams <app-svg-icon icon="down-arrow" color="white"></app-svg-icon></button>
    <button class="btn btn-primary btn-sm shadow me-2 mb-3" [ngClass]="{'active': filteredTemplateIDs.length > 0}" [ngbPopover]="templateSelector"><app-svg-icon class="me-2" icon="filter" color="white" *ngIf="filteredTemplateIDs.length > 0"></app-svg-icon>Events <app-svg-icon icon="down-arrow" color="white"></app-svg-icon></button>
    <button class="btn btn-primary btn-sm shadow me-2 mb-3" (click)="print()"><app-svg-icon class="me-2" icon="printing-document" color="white"></app-svg-icon>Print</button>
    <a class="btn btn-info btn-sm shadow mb-3" href="https://support.plan2gether.com/category/rotas" target="_blank"><app-svg-icon icon="help" color="white"></app-svg-icon> Help</a>
</div>

<ng-container *ngIf="viewType === 'list'">
    <app-rota-event [event]="event" [filteredTeams]="filteredTeamIDs" (deleted)="eventDeleted($event)" *ngFor="let event of filteredEvents"></app-rota-event>
</ng-container>

<ng-container *ngIf="viewType === 'calendar'">
    <app-rota-calendar [events]="filteredEvents" [filteredTeams]="filteredTeamIDs" (deleted)="eventDeleted($event)"></app-rota-calendar>
</ng-container>

<ng-container *ngIf="viewType === 'grid'">
    <app-rota-grid [events]="filteredEvents" [filteredTeams]="filteredTeamIDs" (deleted)="eventDeleted($event)"></app-rota-grid>
</ng-container>

<ng-container *ngIf="errorPasscodeRequired">
    <app-rota-passcode-entry (passcodeEntered)="passcodeEntered($event)" [passcode]="rotaPasscode"></app-rota-passcode-entry>
</ng-container>

<ng-template #viewSelector>
    <div class="view-selector-item" (click)="changeView('list')"><app-svg-icon class="me-2" icon="list"></app-svg-icon>List</div>
    <div class="view-selector-item" (click)="changeView('calendar')"><app-svg-icon class="me-2" icon="calendar"></app-svg-icon>Calendar</div>
    <div class="view-selector-item" (click)="changeView('grid')"><app-svg-icon class="me-2" icon="pixels"></app-svg-icon>Grid</div>
</ng-template>

<ng-template #teamSelector>
    <ul class="list-unstyled mb-0">
        <li>
            <button class="btn btn-outline-primary btn-sm" (click)="clearFilteredTeams()">Show all</button>
        </li>
        <li *ngFor="let team of teams">
            <div class="form-check mb-1">
                <input type="checkbox" class="form-check-input" id="team-{{team.id}}" [checked]="!filteredTeamIDs.includes(team.id)" (change)="updateFilteredTeams(team.id)"> <label class="form-check-label" for="team-{{team.id}}">{{ team.name }}</label>
            </div>
        </li>
    </ul>
</ng-template>

<ng-template #templateSelector>
    <ul class="list-unstyled mb-0">
        <li>
            <button class="btn btn-outline-primary btn-sm" (click)="clearFilteredTemplates()">Show all</button>
        </li>
        <li *ngFor="let template of templates">
            <div class="form-check mb-1">
                <input type="checkbox" class="form-check-input" id="template-{{template.id}}" [checked]="!filteredTemplateIDs.includes(template.id)" (change)="updateFilteredTemplates(template.id)"> <label class="form-check-label" for="template-{{template.id}}">{{ template.name }}</label>
            </div>
        </li>
    </ul>
</ng-template>

<ng-template #dateSelector>
    <app-rota-date-picker (dateRangeChanged)="updateDates($event)"></app-rota-date-picker>
</ng-template>
