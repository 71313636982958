import { Injectable, EventEmitter } from '@angular/core';
import { ApiModule } from './api.module';
import { ApiService } from './api.service';
import { Team } from './models/team.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: ApiModule
})
export class TeamsService {

    constructor(private api: ApiService) { }

    teams?: Team[];

    teamsUpdated = new EventEmitter<Team[]>();

    updateList() {
        this.teams = null;
        this.api.get<Team[]>('teams')
            .subscribe(data => {
                this.teams = data;
                this.teamsUpdated.emit(this.teams);
            }, error => {
                this.teamsUpdated.emit([]);
            });
    }

    addTeam(team: Team) {
        return new Observable<Team>(subscriber => {
            return this.api.post<Team>('teams', team)
                .subscribe(data => {
                    this.updateList();
                    subscriber.next(data);
                    subscriber.complete();
                }, error => {
                    subscriber.next(null);
                    subscriber.complete();
                });
        });
    }

    getTeam(id: string) {
        return this.api.get<Team>('teams/' + id);
    }

    addPerson(teamID: string, personID: string) {
        return this.api.post<Team>('teams/' + teamID + '/people', {id: personID});
    }

    removePerson(teamID: string, personID: string) {
        return this.api.delete<Team>('teams/' + teamID + '/people/' + personID);
    }

    updatePerson(teamID: string, personID: string, isAdmin: boolean) {
        return this.api.put<Team>('teams/' + teamID + '/people/' + personID, {is_admin: isAdmin});
    }

    updateTeam(team: Team) {
        return new Observable<Team>(subscriber => {
            return this.api.put<Team>('teams/' + team.id, team)
                .subscribe(data => {
                    this.updateList();
                    subscriber.next(data);
                    subscriber.complete();
                }, error => {
                    subscriber.next(null);
                    subscriber.complete();
                });
        });
    }

    deleteTeam(teamID: string) {
        return this.api.delete('teams/' + teamID);
    }
}
