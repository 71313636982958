<div class="border rounded shadow-sm bg-white" *ngIf="template != null">
    <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
        <h3 class="p-0">{{ template.name }}</h3>
    </div>
    <div class="p-3 border-bottom" *ngIf="authService.isAdmin">
        <button class="btn btn-warning btn-sm shadow-sm me-1" #p="ngbPopover" (click)="openPopover(p)" [ngbPopover]="editEventTemplate" [autoClose]="false">Edit</button>
        <button class="btn btn-danger btn-sm shadow-sm" (click)="deleteTemplate()">Delete</button>
    </div>
    <div class="p-3">
        <div class="d-flex flex-column overflow-auto">
            <div class="d-flex">
                <div class="item-info-label">Type:</div>
                <div class="item-info-details">{{ template.recurring ? 'Recurring' : 'Non-recurring' }}</div>
            </div>
            <div class="d-flex" *ngIf="template.recurring">
                <div class="item-info-label">Recurs:</div>
                <div class="item-info-details">{{ template.display_days }} <span class="text-nowrap">{{ template.display_times }}</span></div>
            </div>
            <div class="d-flex">
                <div class="item-info-label">Send reminders:</div>
                <div class="item-info-details" *ngIf="template.suppress_reminders">Don't send</div>
                <div class="item-info-details" *ngIf="!template.suppress_reminders && template.remind_hours_before === null">{{ template.organisation.preferences.remind_hours_before }} hours before <span class="badge badge-sm bg-info">Default setting</span></div>
                <div class="item-info-details" *ngIf="!template.suppress_reminders && template.remind_hours_before !== null">{{ template.remind_hours_before }} hours before</div>
            </div>
            <div class="d-flex" *ngIf="authService.isAdmin">
                <div class="item-info-label">Default notes:</div>
                <div class="item-info-details"><button class="btn btn-sm btn-outline-primary" #p="ngbPopover" (click)="openPopover(p)" (click)="startEditingDefaultNotes()" [ngbPopover]="defaultNotesTemplate">Set default notes</button></div>
            </div>
        </div>
    </div>
</div>

<div class="border rounded shadow-sm bg-white mt-3" *ngIf="template != null">
    <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
        <h5 class="p-0">Teams</h5>
    </div>
    <div class="p-3 border-bottom" *ngIf="authService.isAdmin">
        <button type="button" class="btn btn-sm btn-success shadow-sm" #p="ngbPopover" (click)="openPopover(p)" [ngbPopover]="addTeamTemplate" placement="right"><app-svg-icon icon="add" color="white"></app-svg-icon> Add team</button>
    </div>
    <div class="p-3 border-bottom" *ngFor="let templateTeam of template.teams">
        <div class="d-flex align-items-center justify-content-between">
            <h5><a [routerLink]="['/teams', templateTeam.team_id]">{{ templateTeam.team.name }}</a></h5>
            <div>
                <button class="btn btn-sm btn-primary shadow-sm ms-1" *ngIf="authService.isAdminForTeam(templateTeam.team_id)" #p="ngbPopover" (click)="openPopover(p)" (click)="startEditingDefaultNotes(templateTeam)" [ngbPopover]="defaultNotesTemplate">Set default notes</button>
                <button class="btn btn-primary btn-sm shadow-sm ms-1" *ngIf="authService.isAdminForTeam(templateTeam.team_id)" (click)="recreateRotaForTeam(templateTeam.team)">Recreate rota</button>
                <button class="btn btn-danger btn-sm shadow-sm ms-2" *ngIf="authService.isAdmin" (click)="removeTeam(templateTeam)">Remove team</button>
            </div>
        </div>
        <div class="d-flex flex-column overflow-auto pt-2">
            <div class="d-flex" *ngIf="!templateTeam.team.single_role">
                <div class="item-info-label">No of people:</div>
            </div>
            <div class="d-flex" *ngFor="let role of templateTeam.team.roles">
                <div class="item-info-label" *ngIf="templateTeam.team.single_role">No of people:</div>
                <div class="item-info-label" *ngIf="!templateTeam.team.single_role"><a [routerLink]="['/roles', role.id]">{{ role.name }}</a></div>
                <div class="item-info-details">
                    <a href="#" *ngIf="authService.isAdminForTeam(templateTeam.team_id)" (click)="updateAutogenerateCount(templateTeam, role, -1)"><app-svg-icon icon="minus"></app-svg-icon></a>
                    {{ templateRole(templateTeam, role)?.autogenerate_people_count || 0 }}
                    <a href="#" *ngIf="authService.isAdminForTeam(templateTeam.team_id)" (click)="updateAutogenerateCount(templateTeam, role, 1)"><app-svg-icon icon="add"></app-svg-icon></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="border rounded shadow-sm bg-white mt-3" *ngIf="template != null">
    <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
        <h5 class="p-0">Upcoming instances</h5>
    </div>
    <div class="p-3" *ngIf="authService.isAdmin">
        <button class="btn btn-success btn-sm shadow-sm me-1" *ngIf="!template.recurring" #p="ngbPopover" (click)="openPopover(p)" [ngbPopover]="addEvent" [autoClose]="false"><app-svg-icon icon="add" color="white"></app-svg-icon> Add instance</button>
        <button class="btn btn-success btn-sm shadow-sm me-1" *ngIf="template.recurring" #p="ngbPopover" (click)="openPopover(p)" [ngbPopover]="createEventsTemplate"><app-svg-icon icon="add" color="white"></app-svg-icon> Add future instances</button>
        <button class="btn btn-warning btn-sm shadow-sm" *ngIf="template.recurring" (click)="recreateRota()">Re-create upcoming</button>
    </div>
    <div class="p-3 border-top">
        <div class="py-2 border-bottom d-flex align-items-center justify-content-between" *ngFor="let event of template.events">
            <div>
                <h5 class="me-2 my-0">{{ event.name }}</h5>
                {{ event.starts_at | date:"EEE, dd MMM y HH:mm" }} - {{ event.ends_at | date:"HH:mm" }}
            </div>
            <div>
                <button class="btn btn-sm btn-warning shadow-sm me-1" *ngIf="!template.recurring && authService.isAdmin" #p="ngbPopover" (click)="openPopover(p);selectedEvent = event" [ngbPopover]="editEvent" [autoClose]="false">Edit</button>
                <button class="btn btn-sm btn-primary shadow-sm" (click)="showEvent(eventModal, event.id)">View rota</button>
                <button class="btn btn-sm btn-danger shadow-sm ms-1" *ngIf="authService.isAdmin" (click)="deleteEvent(event)">Delete</button>
            </div>
        </div>
    </div>
</div>

<ng-template #addTeamTemplate><app-team-selector (selected)="addTeam($event)"></app-team-selector></ng-template>
<ng-template #addEvent><app-edit-event [template]="template" (saved)="addedEvent($event)" (cancelled)="cancelledPopover()"></app-edit-event></ng-template>
<ng-template #editEvent><app-edit-event [template]="template" [event]="selectedEvent" (saved)="savedEvent($event)" (cancelled)="cancelledPopover()"></app-edit-event></ng-template>
<ng-template #createEventsTemplate>
    <div class="input-group input-group-sm">
        <span class="input-group-text">Until:</span>
        <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="createUntil" ngbDatepicker #d="ngbDatepicker">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><app-svg-icon icon="calendar"></app-svg-icon></button>
    </div>
    <button class="btn btn-primary btn-sm mt-2" (click)="createEvents()">Create Events</button>
</ng-template>
<ng-template #editEventTemplate><app-edit-template [template]="template" (templateSaved)="editedTemplate($event)" (cancelled)="cancelledPopover()"></app-edit-template></ng-template>


<ng-template #eventModal>
    <app-rota-event [event]="selectedEvent" [filteredTeams]="[]" [inList]="false" (deleted)="deletedEvent($event)"></app-rota-event>
</ng-template>

<ng-template #defaultNotesTemplate>
    <div class="d-flex flex-column align-items-start">
        <textarea class="form-control" cols="30" rows="5" [(ngModel)]="editingDefaultNotes"></textarea>
        <button class="btn btn-primary btn-sm shadow-sm mt-2" (click)="saveDefaultNotes()">Save</button>
    </div>
</ng-template>
