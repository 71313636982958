import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Team } from 'src/app/api/models/team.model';
import { TeamsService } from 'src/app/api/teams.service';

@Component({
    selector: 'app-team-selector',
    templateUrl: './team-selector.component.html',
    styles: ['.list-unstyled { margin-bottom: 0; min-width: 100px; }']
})
export class TeamSelectorComponent implements OnInit {

    @Output() selected = new EventEmitter<Team>();

    @Input() teams?: Team[];

    constructor(private service: TeamsService) {
        if (this.teams !== undefined) {
            return;
        }

        if (service.teams !== undefined) {
            this.teams = service.teams;
        } else {
            service.teamsUpdated.subscribe(
                teams => { this.teams = teams; }
            );
            service.updateList();
        }
    }

    ngOnInit(): void {
    }

    selectTeam(team: Team) {
        this.selected.emit(team);
    }

}
