import { Person } from './person.model';

export enum LogEntryType {
    info = 1,
    error = 2
}

export interface LogEntry {
    id: string;
    organisation_id: string;
    person_id: string;
    verbosity: number;
    type: number;
    message: string;
    created_at: string;
    updated_at: string;

    person?: Person;
}
