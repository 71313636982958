import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientService } from './client.service';
import { ApiModule } from '../api/api.module';



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ApiModule
    ]
})
export class WebsocketsModule { }
