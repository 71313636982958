<div class="mb-3">
    <label class="form-label">Name:</label>
    <input type="text" class="form-control form-control-sm" placeholder="{{ template.name }}" [(ngModel)]="name">
</div>
<div class="mb-3">
    <label class="form-label">Date:</label>
    <div class="input-group input-group-sm mb-1">
        <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><app-svg-icon icon="calendar"></app-svg-icon></button>
    </div>
</div>
<div class="mb-3">
    <label class="form-label">Times:</label>
    <app-time-picker [startTime]="startTime" [endTime]="endTime" (startTimeUpdated)="timeUpdated('start', $event)" (endTimeUpdated)="timeUpdated('end', $event)"></app-time-picker>
</div>
<div class="alert alert-danger" *ngIf="formError">{{ formError }}</div>
<div class="d-flex align-items-start justify-content-end">
    <button class="btn btn-sm btn-success me-1" (click)="save()">{{ this.event === undefined ? 'Add' : 'Save' }}</button> 
    <button class="btn btn-sm btn-outline-danger" (click)="cancel()">Cancel</button>
</div>
