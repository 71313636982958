import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { ApiService } from '../api/api.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    @Output() widthChanged = new EventEmitter<number>();

    width = 33;

    items: SidebarItem[] = [
        {link: '/rota', icon: 'calendar', text:'Rota', admin: false},
        {link: '/people', icon: 'user', text:'People', admin: false},
        {link: '/teams', icon: 'team', text:'Teams', admin: false},
        {link: '/events', icon: 'clock', text:'Events', admin: false},
        {link: '/organisation', icon: 'church', text:'Organisation', admin: true}
    ];

    constructor(public authService: AuthService, public apiService: ApiService) { }

    ngOnInit(): void {
        this.widthChanged.emit(this.width);
    }

    toggleNav() {
        if (this.width === 33) {
            this.width = 135;
        } else {
            this.width = 33;
        }

        this.widthChanged.emit(this.width);

        return false;
    }

}

export interface SidebarItem {
    link: string;
    icon: string;
    text: string;
    admin: boolean;
}