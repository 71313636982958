import { Component } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './api/api.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [NgbPopoverConfig]
})
export class AppComponent {
    leftMargin = 0;

    constructor(private config: NgbPopoverConfig, public apiService: ApiService) {
        config.container = 'body';
        config.autoClose = 'outside';
    }

    leftMarginChanged(newValue: number) {
        this.leftMargin = newValue;
    }
}
