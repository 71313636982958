import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { CommonComponentsModule } from '../common-components/common-components.module';



@NgModule({
    declarations: [
        NotificationsListComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule
    ],
    exports: [NotificationsListComponent]
})
export class NotificationsModule { }
