import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api/api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private router: Router,
        private apiService: ApiService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.apiService.isAuthenticated) {
            return true;
        } else {
            return false;
        }
    }
}
