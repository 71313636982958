import { Component, OnInit } from '@angular/core';
import { Notification } from 'src/app/api/models/notification.model';
import { NotificationsService } from 'src/app/api/notifications.service';

@Component({
    selector: 'app-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.css']
})
export class NotificationsListComponent implements OnInit {

    notifications: Notification[] = [];

    constructor(public service: NotificationsService) {
        this.notifications = this.service.notifications;

        this.service.notificationsUpdated.subscribe( n => {
            this.notifications = n;
        });
    }

    ngOnInit(): void {
    }

    markAsRead(notification: Notification) {
        this.service.markAsRead(notification.id);
    }

}
