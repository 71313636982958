import { EventEmitter, Injectable } from '@angular/core';
import { Event } from '../api/models/event.model';
import { OrganisationService } from '../api/organisation.service';
import { ClientService } from './client.service';
import { WebsocketsModule } from './websockets.module';

@Injectable({
    providedIn: WebsocketsModule
})
export class EventUpdatesService {

    eventUpdated = new EventEmitter<Event>();

    eventDeleted = new EventEmitter<string>();

    eventCreated = new EventEmitter<Event>();

    constructor(private client: ClientService, private organisationService: OrganisationService) {
        organisationService.getOrganisationDetails().subscribe(details => {
            client.subscribe('organisation.' + details.id + '.events', 'app.event.updated', data => {
                this.eventUpdated.emit(data.event);
            });
            client.subscribe('organisation.' + details.id + '.events', 'app.event.deleted', data => {
                this.eventDeleted.emit(data.eventID);
            });
            client.subscribe('organisation.' + details.id + '.events', 'app.event.created', data => {
                this.eventCreated.emit(data.event);
            });
        })
    }
}
