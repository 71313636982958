import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPromptService {

    visible = false;

    constructor() { }

    show() {
        console.log('show');
        this.visible = true;
    }
}
