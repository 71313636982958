import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Event } from 'src/app/api/models/event.model';
import * as moment from 'moment';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-rota-calendar',
    templateUrl: './rota-calendar.component.html',
    styleUrls: ['./rota-calendar.component.css']
})
export class RotaCalendarComponent implements OnInit {

    _events: Event[] = [];

    @Input() filteredTeams: string[];
    @Input()
    set events(events: Event[]) {
        this._events = events;
        this.setupCalendar();
    }
    get events() {
        return this._events;
    }

    @Output() deleted = new EventEmitter<Event>();

    dates: Month[] = [];

    selectedDay: Day;
    selectedEvent: Event;
    popover: NgbPopover;

    constructor(public modalService: NgbModal) { }

    ngOnInit(): void {
    }

    setupCalendar() {
        this.dates = [];

        const first = moment(this.events[0].starts_at).date(1);

        let offset = first.day() - 1;
        if (offset < 0) {
            offset += 7;
        }

        const lastEvent = moment(this.events.slice(-1)[0].ends_at);

        const currentDate = first;
        let currentMonth: Month = {name: currentDate.format('MMMM'), year: currentDate.year(), weeks: []};
        let currentWeek: Week = {startOffset: offset, days: []};

        while (currentDate.isSameOrBefore(lastEvent, 'month')) {
            currentWeek.days.push({
                date: currentDate.clone(),
                events: this.events.filter(e => moment(e.starts_at).isSame(currentDate, 'day'))
            });
            currentDate.add(1, 'day');

            if (currentDate.date() === 1) {
                currentMonth.weeks.push(currentWeek);

                while (currentMonth.weeks.length < 6) {
                    currentMonth.weeks.push({startOffset: 0, days: []});
                }

                this.dates.push(currentMonth);
                currentMonth = {name: currentDate.format('MMMM'), year: currentDate.year(), weeks: []};

                if (currentDate.day() === 1) {
                    currentWeek = {startOffset: 0, days: []};
                } else {
                    currentWeek = {startOffset: currentWeek.days.length, days: []};
                }
            } else if (currentDate.day() === 1) {
                // Monday
                currentMonth.weeks.push(currentWeek);
                currentWeek = {startOffset: 0, days: []};
            }
        }
    }

    selectDay(day, popover) {
        this.popover = popover;
        this.selectedDay = day;
        return false;
    }

    showEvent(template, event) {
        this.popover.close();
        this.selectedEvent = event;
        this.modalService.open(template, {size: 'lg'});
    }

    deletedEvent(event: Event) {
        this.deleted.emit(event);
    }

}

interface Month {
    name: string;
    year: number;
    weeks: Week[];
}

interface Week {
    startOffset: number;
    days: Day[];
}

interface Day {
    date: moment.Moment;
    events: Event[];
}