import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RolesService } from 'src/app/api/roles.service';
import { Team } from 'src/app/api/models/team.model';
import { Observable } from 'rxjs';
import { Role } from 'src/app/api/models/role.model';

@Component({
    selector: 'app-role-selector',
    templateUrl: './role-selector.component.html',
    styles: ['.list-unstyled { margin-bottom: 0; min-width: 100px; }']
})
export class RoleSelectorComponent implements OnInit {

    @Input() roles: Role[];

    @Output() selected = new EventEmitter<Role>();

    constructor() { }

    ngOnInit(): void {
    }

    selectRole(role: Role) {
        this.selected.emit(role);
    }

}
