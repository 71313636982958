<nav class="sidenav bg-light border-end" [ngStyle]="{'width.px': width}" *ngIf="apiService.isAuthenticated">
    <div class="h-100 content d-flex flex-column justify-content-between">
        <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let item of items">
                <a class="nav-link" [routerLink]="[item.link]" routerLinkActive #rla="routerLinkActive" *ngIf="!item.admin || authService.isAdmin">
                    <app-svg-icon [icon]="item.icon" [color]="rla.isActive ? '#007bff' : 'black'" [tooltip]="width === 33 ? item.text : ''"></app-svg-icon>
                    {{ item.text}}
                </a>
            </li>
        </ul>
        <ul class="navbar-nav">
            <li class="nav-item">
                <a href="https://support.plan2gether.com" class="nav-link" target="_blank">
                    <app-svg-icon icon="help" [tooltip]="width === 33 ? 'Support' : ''"></app-svg-icon>
                    Support
                </a>
            </li>
        </ul>
    </div>
</nav>
