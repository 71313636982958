<div class="d-flex align-items-start h-100">
    <div class="items-list flex-column" [ngClass]="{'d-none d-sm-flex': selectedTeamID !== null, 'd-flex': selectedTeamID === null}">
        <div class="d-flex flex-wrap align-items-center mb-3" *ngIf="authService.isAdmin">
            <button class="btn btn-success btn-sm shadow me-2" (click)="openPopover(p)" #p="ngbPopover" [ngbPopover]="addTeamTemplate">
                <app-svg-icon icon="add" color="white"></app-svg-icon>
                Add new
            </button>
            <a class="btn btn-info btn-sm shadow" href="https://support.plan2gether.com/category/teams" target="_blank"><app-svg-icon icon="help" color="white"></app-svg-icon> Help</a>
        </div>

        <div class="border rounded-top shadow-sm bg-white">
            <div class="border-bottom rounded-top p-1 bg-light">
                <span>{{ teams.length }} teams</span>
            </div>
            <ul class="list-group list-group-flush">
                <button type="button" (click)="selectTeam(team)" class="list-group-item list-group-item-action py-1 ps-3 pe-2" [ngClass]="{'bg-medium': team.id === selectedTeamID}" *ngFor="let team of teams">
                    {{ team.name }}
                </button>
            </ul>
        </div>
    </div>
    <div class="ps-sm-2 item-details sticky-top" *ngIf="selectedTeamID !== null">
        <div class="d-sm-none mb-3"><a class="btn btn-primary btn-sm shadow" [routerLink]="['/teams']">&lt; Back to all teams</a></div>
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #addTeamTemplate>
    <app-edit-team (teamSaved)="addedTeam($event)"></app-edit-team>
</ng-template>
