import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsPageComponent } from './events-page/events-page.component';
import { EventTemplateInfoComponent } from './event-template-info/event-template-info.component';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { FormsModule } from '@angular/forms';
import { TeamsModule } from '../teams/teams.module';
import { EditEventComponent } from './edit-event/edit-event.component';
import { RotaModule } from '../rota/rota.module';



@NgModule({
    declarations: [EventsPageComponent, EventTemplateInfoComponent, EditTemplateComponent, EditEventComponent],
    imports: [
        CommonModule,
        RouterModule,
        NgbPopoverModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        CommonComponentsModule,
        FormsModule,
        TeamsModule,
        RotaModule
    ]
})
export class EventsModule { }
