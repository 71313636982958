<input type="text" #search [value]="searchTerm" (input)="updateSearchTerm($event.target.value)" spellcheck="false">
<div class="people-list">
    <div class="d-flex flex-column person border-bottom" *ngFor="let person of filteredPeople" (click)="selectPerson(person)">
        <div>
            {{ person.first_name }} {{ person.last_name }}
            <app-svg-icon class="ms-1" icon="alert" color="danger" tooltip="This person is unavailable for this event" *ngIf="unavailablePeople.includes(person.id)"></app-svg-icon>
        </div>
        <div class="align-self-end text-secondary person-displayname">{{ person.display_name }}</div>
    </div>
</div>
