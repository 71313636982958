<div class="input-group input-group-sm mb-1">
    <input type="text" class="form-control" placeholder="Start time" [ngbPopover]="timePopover" autoClose="outside" #pS="ngbPopover" (click)="$event.target.select();setPopover(pS, 'start')" (change)="changedStartTime()" [(ngModel)]="startTime">
    <span class="input-group-text"> - </span>
    <input type="text" class="form-control" placeholder="End time" [ngbPopover]="timePopover" autoClose="outside" #pE="ngbPopover" (click)="$event.target.select();setPopover(pE, 'end')" (change)="changedEndTime()" [(ngModel)]="endTime">
</div>

<ng-template #timePopover>
    <div class="popover-list">
        <ul class="list-unstyled">
            <li *ngFor="let time of times" id="time-{{ time | alphanumeric }}">
                <button class="btn btn-sm mt-0" [ngClass]="{'text-primary': currentPopoverField === 'start' ? (startTime === time) : (endTime === time) }" (click)="setTime(time)">{{ time }}</button>
            </li>
        </ul>
    </div>
</ng-template>
