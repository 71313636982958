import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RotaPageComponent } from './rota/rota-page/rota-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { PeoplePageComponent } from './people/people-page/people-page.component';
import { AuthGuard } from './auth.guard';
import { TeamsPageComponent } from './teams/teams-page/teams-page.component';
import { PersonInfoComponent } from './people/person-info/person-info.component';
import { TeamInfoComponent } from './teams/team-info/team-info.component';
import { EventsPageComponent } from './events/events-page/events-page.component';
import { RoleInfoComponent } from './roles/role-info/role-info.component';
import { EventTemplateInfoComponent } from './events/event-template-info/event-template-info.component';
import { LogsPageComponent } from './organisation/logs-page/logs-page.component';
import { OrganisationPageComponent } from './organisation/organisation-page/organisation-page.component';


const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: '/rota'},
    {path: 'rota', component: RotaPageComponent},
    {path: 'people', component: PeoplePageComponent, canActivate: [AuthGuard], children: [
        {path: ':id', component: PersonInfoComponent}
    ]},
    {path: 'teams', component: TeamsPageComponent, canActivate: [AuthGuard], children: [
        {path: ':id', component: TeamInfoComponent}
    ]},
    {path: 'roles/:id', component: RoleInfoComponent, canActivate: [AuthGuard]},
    {path: 'events', component: EventsPageComponent, canActivate: [AuthGuard], children: [
        {path: 'templates/:id', component: EventTemplateInfoComponent}
    ]},
    {path: 'login', component: LoginPageComponent},
    {path: 'organisation', component: OrganisationPageComponent},
    {path: 'logs', component: LogsPageComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
