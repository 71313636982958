import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleInfoComponent } from './role-info/role-info.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { RoleSelectorComponent } from './role-selector/role-selector.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { EditRoleComponent } from './edit-role/edit-role.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonSelectorModule } from '../person-selector/person-selector.module';



@NgModule({
  declarations: [RoleInfoComponent, RoleSelectorComponent, EditRoleComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    PersonSelectorModule,
    CommonComponentsModule,
    FormsModule
  ],
  exports: [RoleSelectorComponent, EditRoleComponent]
})
export class RolesModule { }
