import { EventTemplate } from './eventtemplate.model';
import { Team } from './team.model';
import { Person } from './person.model';
import { Role } from './role.model';
import * as moment from 'moment';

// tslint:disable:variable-name
export class Event {
    id: string;
    organisation_id: string;
    event_template_id: string;
    starts_at: string;
    ends_at: string;
    name: string;
    notes: string;
    created_at: string;
    updated_at: string;
    teams: EventTeam[];
    people: EventPerson[];
    event_template?: EventTemplate;

    get display_times(): string {
        return moment(this.starts_at).format('HH:mm') + ' - ' + moment(this.ends_at).format('HH:mm');
    }
}
// tslint:enable:variable-name

export interface AddingEvent {
    event_template_id: string;
    name: string;
    date: string;
    start_time: number;
    end_time: number;
}

export interface EventTeam {
    id: string;
    event_id: string;
    team_id: string;
    name: string;
    notes: string;
    created_at: string;
    updated_at: string;
    team: Team;
    roles: EventTeamRole[];
}

export interface EventTeamRole {
    id: string;
    event_team_id: string;
    role_id: string;
    name: string;
    notes: string;
    created_at: string;
    updated_at: string;
    role: Role;
}

export interface EventPerson {
    id: string;
    event_id: string;
    event_team_role_id: string;
    person_id: string;
    issues: number;
    created_at: string;
    updated_at: string;
    person: Person;
}

export enum EventPersonIssues {
    Unavailable = 1,
    Conflict = 2
}