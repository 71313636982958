import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Person } from 'src/app/api/models/person.model';
import { Validators, FormBuilder } from '@angular/forms';
import { PeopleService } from 'src/app/api/people.service';
import { AuthService } from 'src/app/api/auth.service';
import { ToastService } from 'src/app/common-components/toast.service';

@Component({
    selector: 'app-edit-person',
    templateUrl: './edit-person.component.html'
})
export class EditPersonComponent implements OnInit, AfterViewInit {

    personForm = this.formBuilder.group({
        first_name: [''],
        last_name: ['', Validators.required],
        display_name: ['', Validators.required],
        email: ['', Validators.email],
        phone: [''],
        is_admin: [false],
        send_welcome_email: [false]
    });

    @Input() person?: Person;

    @Output() saved = new EventEmitter<Person>();

    @ViewChild('first_name') fnElement: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private peopleService: PeopleService,
        public authService: AuthService,
        private toastService: ToastService
    ) { }

    ngOnInit() {
        const component = this;
        this.personForm.get('first_name').valueChanges.subscribe({
            next() {
                component.updateDisplayName();
            }
        });
        this.personForm.get('last_name').valueChanges.subscribe({
            next() {
                component.updateDisplayName();
            }
        });

        if (this.person !== undefined) {
            this.personForm.setValue({
                first_name: this.person.first_name,
                last_name: this.person.last_name,
                display_name: this.person.display_name,
                email: this.person.email,
                phone: this.person.phone,
                is_admin: this.person.is_admin,
                send_welcome_email: false
            });
            if (this.person.user_id !== null) {
                this.personForm.get('email').disable();
            }
            if (this.person.id === this.authService.me.id) {
                this.personForm.get('is_admin').disable();
            }
        }
    }

    ngAfterViewInit(): void {
        this.fnElement.nativeElement.focus();
    }

    updateDisplayName() {
        let name = '';
        const firstName = this.personForm.get('first_name').value;
        const lastName = this.personForm.get('last_name').value;

        if (firstName !== '') {
            name += firstName[0] + ' ';
        }

        name += lastName;

        this.personForm.get('display_name').setValue(name);
    }

    submit() {
        if (this.person === undefined) {
            const person: Person = Object.assign(new Person(), this.personForm.value);
            this.peopleService.addPeople([person], this.personForm.get('send_welcome_email').value)
                .subscribe(
                    result => {
                        if (result.failedPeople.length > 0) {
                            this.toastService.showError('Error adding new person');
                        }
                        if (result.duplicatePeople.length > 0) {
                            this.toastService.showError('Unable to add duplicate person. Please change either name or display name');
                        }
                        if (result.people.length > 0) {
                            this.saved.emit(result.people[0]);
                        }
                    }
                );
        } else {
            const person = Object.assign(new Person(), this.person);
            Object.assign(person, this.personForm.value);
            this.peopleService.updatePerson(person)
                .subscribe(
                    result => {
                        this.saved.emit(result);
                    }
                );
        }
    }
}
