import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterViewInit, ViewChild,
    ElementRef } from '@angular/core';
import { Person } from 'src/app/api/models/person.model';
import { PeopleService } from 'src/app/api/people.service';
import { isArray } from 'util';
import { Observable } from 'rxjs';
import { UnavailabilitiesService } from 'src/app/api/unavailabilities.service';

@Component({
    selector: 'app-person-selector',
    templateUrl: './person-selector.component.html',
    styleUrls: ['./person-selector.component.css']
})
export class PersonSelectorComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChild('search') searchField: ElementRef;

    @Output() selected = new EventEmitter<Person>();

    @Input() disallowedPersonIDs: string[] = [];

    @Input() people?: Person[];

    @Input() availabilityStart?: string;

    @Input() availabilityEnd?: string;

    filteredPeople: Person[] = [];
    unavailablePeople: string[] = [];
    searchTerm = '';

    constructor(private service: PeopleService, private unavailabilitiesService: UnavailabilitiesService) {
        this.service.peopleUpdated.subscribe(people => {
            this.updateAvailability(people);
            this.updateSearchTerm(this.searchTerm);
        });
    }

    ngOnInit() {
        if (this.people === undefined) {
            this.service.updateList();
        } else {
            this.updateAvailability(this.people);
            this.updateSearchTerm(this.searchTerm);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['disallowedPersonIDs']) {
            this.updateSearchTerm(this.searchTerm);
        }
    }

    ngAfterViewInit() {
        this.searchField.nativeElement.focus();
    }

    updateAvailability(people: Person[]) {
        if (this.availabilityStart === undefined) {
            return;
        }

        const end = this.availabilityEnd ?? this.availabilityStart;

        this.unavailabilitiesService.checkPeopleForAvailability(people.map(p => p.id), this.availabilityStart, end)
            .subscribe(p => this.unavailablePeople = p);
    }

    updateSearchTerm(searchTerm: string) {

        let allPeople: Person[];

        if (this.people !== undefined) {
            allPeople = this.people;
        } else {
            allPeople = this.service.people;
        }

        if (allPeople === undefined) {
            return;
        }

        this.searchTerm = searchTerm.toLocaleLowerCase();

        if (searchTerm === '') {
            this.filteredPeople = allPeople.filter( person => !this.disallowedPersonIDs.includes(person.id) );
            return;
        }

        this.filteredPeople = allPeople.filter( person => {

            if (this.disallowedPersonIDs.includes(person.id)) {
                return false;
            }

            const firstName = person.first_name.toLocaleLowerCase();
            const lastName = person.last_name.toLocaleLowerCase();

            if (this.searchTerm.includes(' ')) {
                const words = this.searchTerm.split(' ').filter( word => word.length > 0 );

                let match = true;

                words.forEach(word => {
                    if (!firstName.includes(word) && !lastName.includes(word)) {
                        match = false;
                    }
                });

                return match;

            } else if (firstName.includes(this.searchTerm) || lastName.includes(this.searchTerm)) {
                return true;
            }

            return false;
        });
    }

    selectPerson(person: Person) {
        this.selected.emit(person);
    }

}
