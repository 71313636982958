import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Event } from './models/event.model';
import { ApiModule } from './api.module';

@Injectable({
    providedIn: ApiModule
})
export class RotaService {

    constructor(private api: ApiService) { }

    deletePerson(eventPerson: string) {
        return this.api.delete<Event>('event-people/' + eventPerson);
    }

    addPerson(event: string, role: string, person: string) {
        return this.api.post<Event>('events/' + event + '/people', {id: person, role_id: role});
    }
}
