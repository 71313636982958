<div class="modal-header">
    <h4 class="model-title">Upload CSV</h4>
    <button class="btn-close" type="button" (click)="activeModal.dismiss('Close click')"></button>
</div>
<div class="modal-body">
    <p>Select a csv file to upload:</p>
    <div class="mb-3"><input class="form-control" type="file" name="file" id="file" (change)="readCSV($event)" accept="text/csv"></div>
    <div *ngIf="csvData.length > 0">
        <p>{{ csvData.length }} people found.</p>
        <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="firstRowHeadersCheck" [(ngModel)]="firstRowHeaders" (change)="updateCSVData()">
            <label class="form-check-label" for="firstRowHeadersCheck">Column headers in first row</label>
        </div>
        <p>Select columns:</p>
        <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">First name:</label>
            <div class="col-sm-8">
                <select [(ngModel)]="firstNameField" class="form-select">
                    <option *ngFor="let field of csvFields" [value]="field.name">{{field.display}}</option>
                </select>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Last name:</label>
            <div class="col-sm-8">
                <select [(ngModel)]="lastNameField" class="form-select">
                    <option *ngFor="let field of csvFields" [value]="field.name">{{field.display}}</option>
                </select>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Display name:</label>
            <div class="col-sm-8">
                <select [(ngModel)]="displayNameField" class="form-select">
                    <option value="first-initial-last-name">-- Use first initial & last name --</option>
                    <option value="first-name-last-name">-- Use first name & last name --</option>
                    <option *ngFor="let field of csvFields" [value]="field.name">{{field.display}}</option>
                </select>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Email:</label>
            <div class="col-sm-8">
                <select [(ngModel)]="emailField" class="form-select">
                    <option value=""></option>
                    <option *ngFor="let field of csvFields" [value]="field.name">{{field.display}}</option>
                </select>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Phone:</label>
            <div class="col-sm-8">
                <select [(ngModel)]="phoneField" class="form-select">
                    <option value=""></option>
                    <option *ngFor="let field of csvFields" [value]="field.name">{{field.display}}</option>
                </select>
            </div>
        </div>
        <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" ngModel="sendWelcomeEmail" id="send_welcome_email">
            <label class="form-check-label" for="send_welcome_email">Send welcome emails</label>
        </div>
        <button class="btn btn-primary" *ngIf="!uploadComplete" (click)="uploadPeople()">Upload</button>
    </div>
    <p *ngIf="uploadComplete"><button class="btn btn-success" (click)="done()">Done</button><br><br>Uploaded {{ uploadedPeople }} and skipped {{ skippedDuplicates }} duplicate people. {{ failedPeople.length }} people had errors. <button class="btn btn-sm btn-link" (click)="viewErrors()">{{ showErrors ? 'Hide' : 'View' }} errors</button></p>
    <p style="white-space: pre-line;" *ngIf="showErrors">{{ errors }}</p>
</div>
