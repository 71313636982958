import { Component, OnInit } from '@angular/core';
import { LogsService } from 'src/app/api/logs.service';
import { PaginatedResource } from 'src/app/api/interfaces/paginatedresource';
import { LogEntry, LogEntryType } from 'src/app/api/models/logentry';

@Component({
    selector: 'app-logs-page',
    templateUrl: './logs-page.component.html'
})
export class LogsPageComponent implements OnInit {

    LogEntryType = LogEntryType;

    logs: PaginatedResource<LogEntry>;
    verbosity = 1;
    page = 1;

    constructor(private service: LogsService) { }

    ngOnInit(): void {
        this.loadLogs();
    }

    pageChanged(page: number) {
        this.page = page;
        this.loadLogs();
    }

    setVerbosity(verbosity: number) {
        this.verbosity = verbosity;
        this.loadLogs();
    }

    loadLogs() {
        this.service.listLogs(this.page, this.verbosity).subscribe(logs => {
            this.logs = logs;
        });
    }

}
