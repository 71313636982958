import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { EventTemplatesService } from 'src/app/api/event-templates.service';
import { EventTemplate } from 'src/app/api/models/eventtemplate.model';

export enum ReminderOptions {
    organisationDefault,
    suppress,
    specifyHoursBefore
}
@Component({
    selector: 'app-edit-template',
    templateUrl: './edit-template.component.html'
})
export class EditTemplateComponent implements OnInit, AfterViewInit {

    @Input() template?: EventTemplate;
    @Output() templateSaved = new EventEmitter<EventTemplate>();
    @Output() cancelled = new EventEmitter();

    @ViewChild('name') nameElement: ElementRef;

    eventName = '';
    recurring = true;
    date = {year: 0, month: 0, day: 0};
    startTime = '09:00';
    endTime = '10:00';
    recurPeriod = 1;
    recurDays = 1;
    recurWeeks = 0;
    recurWeekCheckboxes = {first: false, second: false, third: false, fourth: false, fifth: false, last: false};
    nextScheduledAt: string;

    ReminderOptions = ReminderOptions;

    selectedReminderOption: ReminderOptions = ReminderOptions.organisationDefault;
    reminderNumber = 24;
    reminderMultiplier = 1;

    updateUpcoming = false;

    formError = '';

    recurDaysOptions = [
        { value: 1, text: 'Monday' }
    ];

    nextScheduledAtOptions: moment.Moment[] = [];

    constructor(private service: EventTemplatesService) { }

    ngOnInit(): void {
        if (this.template === undefined) {
            const today = moment();
            this.date = {year: today.year(), month: today.month()+1, day: today.date()};
        } else {
            this.eventName = this.template.name;
            this.recurring = this.template.recurring;
            const momentDate = moment(this.template.date);
            this.date = {year: momentDate.year(), month: momentDate.month()+1, day: momentDate.date()};
            this.startTime = this.template.display_times.split(' - ')[0];
            this.endTime = this.template.display_times.split(' - ')[1];
            this.recurPeriod = this.template.recur_period || 1;
            this.recurDays = this.template.recur_days || 1;
            this.recurWeeks = this.template.recur_weeks || 0;

            if (this.recurWeeks > 0) {
                if (this.recurPeriod == 7) {
                    this.recurPeriod = 107;
                }
                this.recurWeekCheckboxes.first = (this.recurWeeks & 1) !== 0;
                this.recurWeekCheckboxes.second = (this.recurWeeks & 2) !== 0;
                this.recurWeekCheckboxes.third = (this.recurWeeks & 4) !== 0;
                this.recurWeekCheckboxes.fourth = (this.recurWeeks & 8) !== 0;
                this.recurWeekCheckboxes.fifth = (this.recurWeeks & 16) !== 0;
                this.recurWeekCheckboxes.last = (this.recurWeeks & 32) !== 0;
            }

            if (this.template.suppress_reminders) {
                this.selectedReminderOption = ReminderOptions.suppress;
            } else if (this.template.remind_hours_before !== null) {
                this.selectedReminderOption = ReminderOptions.specifyHoursBefore;
                this.reminderNumber = this.template.remind_hours_before;
            }

            if (this.template.events.length > 0) {
                this.nextScheduledAt = moment(this.template.events[0].starts_at).format('YYYY-MM-DD');
            } else {
                this.nextScheduledAt = moment(this.template.next_scheduled_at).format('YYYY-MM-DD');
            }

            this.updateRecurDayOptions();
            this.generateNextScheduledAtOptions();
        }
    }

    ngAfterViewInit(): void {
        this.nameElement.nativeElement.focus();
    }

    timeUpdated(which: string, time: string) {
        if (which === 'start') {
            this.startTime = time;
        } else {
            this.endTime = time;
        }
    }

    addTemplate() {
        this.formError = '';

        if (this.eventName.length === 0) {
            this.formError = 'Name is required';
            return;
        }

        const regex = /^[0-9][0-9]:[0-9][0-9]$/;

        if (!regex.test(this.startTime)) {
            this.formError = 'Invalid start time';
            return;
        }

        if (!regex.test(this.endTime)) {
            this.formError = 'Invalid end time';
            return;
        }

        const startComponents = this.startTime.split(':').map(str => Number(str));
        const startTime = startComponents[0] * 60 + startComponents[1];

        const endComponents = this.endTime.split(':').map(str => Number(str));
        const endTime = endComponents[0] * 60 + endComponents[1];

        if (endTime < startTime) {
            this.formError = 'End time must be after start time';
            return;
        }

        const suppressReminders = this.selectedReminderOption === ReminderOptions.suppress;
        const remindHoursBefore = this.selectedReminderOption === ReminderOptions.specifyHoursBefore ? this.reminderNumber * this.reminderMultiplier : null;

        const date = '' + this.date.year + '-'
        + (this.date.month < 10 ? '0' : '') + this.date.month + '-'
        + (this.date.day < 10 ? '0' : '') + this.date.day;

        this.recurWeeks = 0;

        if (this.recurPeriod == 107) {
            if (this.recurWeekCheckboxes.first) this.recurWeeks |= 1;
            if (this.recurWeekCheckboxes.second) this.recurWeeks |= 2;
            if (this.recurWeekCheckboxes.third) this.recurWeeks |= 4;
            if (this.recurWeekCheckboxes.fourth) this.recurWeeks |= 8;
            if (this.recurWeekCheckboxes.fifth) this.recurWeeks |= 16;
            if (this.recurWeekCheckboxes.last) this.recurWeeks |= 32;
        }

        if (this.template === undefined) {
            const template: EventTemplate = {
                name: this.eventName,
                recurring: this.recurring,
                date,
                start_time: startTime,
                end_time: endTime,
                recur_period: this.recurPeriod % 100,
                recur_days: this.recurDays,
                recur_weeks: this.recurWeeks,
                next_scheduled_at: this.nextScheduledAt,
                display_days: '',
                display_times: '',
                suppress_reminders: suppressReminders,
                remind_hours_before: remindHoursBefore,
                events: []
            };

            this.service.addTemplate(template).subscribe(
                result => {
                    if (result !== null) {
                        this.templateSaved.emit(result);
                    }
                }
            );
        } else {
            this.template.name = this.eventName;
            this.template.recurring = this.recurring;
            this.template.date = date;
            this.template.start_time = startTime;
            this.template.end_time = endTime;
            this.template.recur_period = this.recurPeriod % 100;
            this.template.recur_days = this.recurDays;
            this.template.recur_weeks = this.recurWeeks;
            this.template.next_scheduled_at = this.nextScheduledAt;
            this.template.updateUpcoming = this.updateUpcoming;
            this.template.suppress_reminders = suppressReminders;
            this.template.remind_hours_before = remindHoursBefore;

            this.service.updateTemplate(this.template).subscribe(
                result => {
                    if (result !== null) {
                        this.templateSaved.emit(result);
                    }
                }
            );
        }

    }

    cancel() {
        this.cancelled.emit();
    }

    updateRecurDayOptions() {
        this.recurPeriod = Number(this.recurPeriod);

        this.recurDaysOptions = [];

        if (this.recurPeriod == 7 || this.recurPeriod == 107) {
            this.recurDaysOptions = [
                { value: 1, text: 'Monday' },
                { value: 2, text: 'Tuesday' },
                { value: 3, text: 'Wednesday' },
                { value: 4, text: 'Thursday' },
                { value: 5, text: 'Friday' },
                { value: 6, text: 'Saturday' },
                { value: 7, text: 'Sunday' }
            ];
        } else if (this.recurPeriod === 30) {
            for (let day = 1; day < 32; day++) {
                this.recurDaysOptions.push({ value: day, text: '' + day + this.nth(day) });
            }
        }
    }

    generateNextScheduledAtOptions() {
        this.nextScheduledAtOptions = [];

        for (let days = 0; days < this.recurPeriod; days++) {
            this.nextScheduledAtOptions.push(moment().add(days, 'days'));
        }

        if (this.nextScheduledAtOptions.filter(d => d.format('YYYY-MM-DD') === this.nextScheduledAt).length === 0) {
            this.nextScheduledAt = this.nextScheduledAtOptions[0].format('YYYY-MM-DD');
        }
    }

    nth(day): string {
        if (day > 3 && day < 21) {
            return 'th';
        }
        switch (day % 10) {
            case 1:  return 'st';
            case 2:  return 'nd';
            case 3:  return 'rd';
            default: return 'th';
        }
    }

    updateReminderOptions() {
        this.selectedReminderOption = Number(this.selectedReminderOption);
    }

    setReminderMultiplier(multiplier: number) {
        this.reminderMultiplier = multiplier;

        if (multiplier === 24) {
            this.reminderNumber = Math.ceil(this.reminderNumber / multiplier);
        } else {
            this.reminderNumber = this.reminderNumber * 24;
        }
    }

}
