<div class="form-check">
    <input type="radio" id="upcoming" class="form-check-input" [value]="DateRange.upcoming" [(ngModel)]="selectedDateRange" (change)="changedSelection()">
    <label for="upcoming" class="form-check-label">All upcoming (default)</label>
</div>
<div class="form-check">
    <input type="radio" id="last28" class="form-check-input" [value]="DateRange.last28" [(ngModel)]="selectedDateRange" (change)="changedSelection()">
    <label for="last28" class="form-check-label">Last 28 days</label>
</div>
<div class="form-check">
    <input type="radio" id="custom" class="form-check-input" [value]="DateRange.custom" [(ngModel)]="selectedDateRange" (change)="changedSelection()">
    <label for="custom" class="form-check-label">Custom date range:</label>
</div>

<div class="mb-2 ms-3" *ngIf="selectedDateRange === DateRange.custom">
    <label class="form-label">From:</label>
    <div class="input-group input-group-sm mb-2">
        <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="startDate" ngbDatepicker #ds="ngbDatepicker" (change)="updateStartDate()" (dateSelect)="updateStartDate()">
        <button class="btn btn-outline-secondary" (click)="ds.toggle()" type="button"><app-svg-icon icon="calendar"></app-svg-icon></button>
    </div>
</div>
<div class="ms-3" *ngIf="selectedDateRange === DateRange.custom">
    <label class="form-label">Until:</label>
    <div class="input-group input-group-sm">
        <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="endDate" ngbDatepicker #de="ngbDatepicker" (change)="updateEndDate()" (dateSelect)="updateEndDate()">
        <button class="btn btn-outline-secondary" (click)="de.toggle()" type="button"><app-svg-icon icon="calendar"></app-svg-icon></button>
    </div>
</div>
