<div class="col-md-3 mx-auto mt-4">
    <div class="card">
        <h3 class="card-header">Private rota</h3>
        <div class="card-body">
            <p>Enter the passcode or log in to view your organisation's rota.</p>
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Passcode" [(ngModel)]="passcode" (keyup.enter)="submitPasscode()">
                <button class="btn btn-primary" (click)="submitPasscode()">Submit</button>
            </div>
        </div>
    </div>
</div>
