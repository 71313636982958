import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventsService } from 'src/app/api/events.service';
import { Event, EventTeam } from 'src/app/api/models/event.model';
import { ConfirmationService } from 'src/app/common-components/confirmation.service';
import { ToastService } from 'src/app/common-components/toast.service';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

    @Input() eventTeam?: EventTeam;
    @Input() event?: Event;
    @Input() canEdit: boolean;
    @Output() didEdit = new EventEmitter<string>();

    notes = '';
    editingNotes: string;

    editing = false;

    constructor(
        private toastService: ToastService,
        private eventsService: EventsService,
        private confirmationService: ConfirmationService
    ) { }

    ngOnInit(): void {
        if (this.eventTeam !== undefined) {
            this.notes = this.eventTeam.notes;
        } else if (this.event !== undefined) {
            this.notes = this.event.notes;
        }
    }

    changedNotes(event: KeyboardEvent, notes: string) {
        if (notes.length > 1022 && (event.key.length === 1 || event.key === 'Enter')) {
            this.toastService.showError('Notes are limited to 1023 characters');
            event.stopPropagation();
            return false;
        }
    }

    pasted(event: any, notes: string) {
        const pastedText: string = event.clipboardData.getData('text.plain');
        if (notes.length + pastedText.length > 1022) {
            this.toastService.showError('Notes are limited to 1023 characters');
            event.stopPropagation();
            return false;
        }
    }

    change(notes: string) {
        this.editingNotes = notes;
    }

    toggleEditing() {
        if (this.editing) {
            if (this.editingNotes.length > 1023) {
                this.toastService.showError('Notes are limited to 1023 characters');
                return;
            }
            this.notes = this.editingNotes.trim();
            this.save();
        } else {
            this.editingNotes = this.notes;
        }

        this.editing = !this.editing;
    }

    save() {
        if (this.eventTeam !== undefined) {
            this.eventsService.updateTeamNotes(this.eventTeam.event_id, this.eventTeam.team_id, this.editingNotes).subscribe(
                _ => { this.didEdit.emit(this.editingNotes); }
            );
        } else {
            this.eventsService.updateNotes(this.event.id, this.editingNotes).subscribe(
                _ => { this.didEdit.emit(this.editingNotes); }
            );
        }
    }

    send() {
        if (this.eventTeam !== undefined) {
            this.confirmationService.confirm('send these notes by email to the people in the ' + this.eventTeam.name + ' team for this event.', 'Send', answer => {
                if (answer) {
                    this.eventsService.sendTeamNotes(this.eventTeam.event_id, this.eventTeam.team_id).subscribe(
                        _ => { this.toastService.showSuccess('Notes sent'); }
                    );
                }
            });
        } else {
            this.confirmationService.confirm('send these notes by email to all people for this event.', 'Send', answer => {
                if (answer) {
                    this.eventsService.sendNotes(this.event.id).subscribe(
                        _ => { this.toastService.showSuccess('Notes sent'); }
                    );
                }
            });
        }
    }

}
