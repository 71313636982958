import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamsPageComponent } from './teams-page/teams-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TeamInfoComponent } from './team-info/team-info.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditTeamComponent } from './edit-team/edit-team.component';
import { TeamSelectorComponent } from './team-selector/team-selector.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { RolesModule } from '../roles/roles.module';
import { PersonSelectorModule } from '../person-selector/person-selector.module';



@NgModule({
    declarations: [TeamsPageComponent, TeamInfoComponent, EditTeamComponent, TeamSelectorComponent],
    imports: [
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        CommonComponentsModule,
        RolesModule,
        PersonSelectorModule
    ],
    exports: [TeamSelectorComponent]
})
export class TeamsModule { }
