import { Component, OnInit } from '@angular/core';
import { Team } from 'src/app/api/models/team.model';
import { TeamsService } from 'src/app/api/teams.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';

@Component({
    selector: 'app-teams-page',
    templateUrl: './teams-page.component.html'
})
export class TeamsPageComponent implements OnInit {

    teams: Team[] = [];
    selectedTeamID?: string;

    popover: NgbPopover;

    constructor(private service: TeamsService, private router: Router, public authService: AuthService) {
        this.service.teamsUpdated.subscribe(teams => {this.teams = teams} );
        this.router.events.subscribe(event => { 
            if (event instanceof NavigationEnd && event.url !== '/teams') {
                this.selectedTeamID = event.url.split('/').pop();
            } else if (event instanceof NavigationEnd && event.url === '/teams') {
                this.selectedTeamID = null;
            }
        });
    }

    ngOnInit() {
        this.service.updateList();
    }

    openPopover(popover) {
        this.popover = popover;
    }

    addedTeam(team) {
        this.popover.close();
        this.selectTeam(team);
    }

    selectTeam(team: Team) {
        this.selectedTeamID = team.id;
        this.router.navigate(['teams', team.id]);
    }
}
