import { Component, OnInit } from '@angular/core';
import { RolesService } from 'src/app/api/roles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/api/models/role.model';
import { TeamsService } from 'src/app/api/teams.service';
import { Person } from 'src/app/api/models/person.model';
import { AuthService } from 'src/app/api/auth.service';
import { ToastService } from 'src/app/common-components/toast.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService } from 'src/app/common-components/confirmation.service';

@Component({
    selector: 'app-role-info',
    templateUrl: './role-info.component.html'
})
export class RoleInfoComponent implements OnInit {

    role: Role;

    personIDs: string[];
    teamPeople: Person[];

    popover: NgbPopover;

    constructor(
        private route: ActivatedRoute,
        private service: RolesService,
        private teamsService: TeamsService,
        public authService: AuthService,
        private toastService: ToastService,
        private router: Router,
        private confirmationService: ConfirmationService
    ) {
        this.route.params.subscribe(
            params => {
                this.service.getRole(params['id'])
                    .subscribe(
                        data => {
                            this.role = data;

                            if (this.role.team.single_role) {
                                this.router.navigate(['teams', this.role.team_id]);
                            }

                            this.personIDs = this.role.people.map( person => person.id );
                            this.teamsService.getTeam(this.role.team.id)
                                .subscribe(
                                    teamData => {
                                        this.teamPeople = teamData.people;
                                    }
                                );
                        }
                    );
            }
        );
    }

    ngOnInit() {
    }

    openPopover(p) {
        this.popover = p;
    }

    deleteRole() {
        this.confirmationService.confirm(
            'delete the role ' + this.role.name + '. This will permanently remove it from all events.',
            'Delete',
            answer => {
                if (answer) {
                    this.service.deleteRole(this.role.id).subscribe(
                        _ => {
                            this.toastService.showSuccess('Role ' + this.role.name + ' deleted.');
                            this.router.navigate(['teams', this.role.team_id]);
                        }
                    );
                }
            }
        );
    }

    roleEdited(role: Role) {
        this.role = role;
        this.popover.close();
    }

    addPerson(person: Person) {
        this.service.addPerson(this.role.id, person.id).subscribe(
            role => {
                this.role = role;
                this.personIDs = this.role.people.map( p => p.id );
            }
        );
    }

    removePerson(person: Person) {
        this.service.removePerson(this.role.id, person.id).subscribe(
            role => {
                this.role = role;
                this.personIDs = this.role.people.map( p => p.id );
            }
        );
        return false;
    }

    toggleReserve(person: Person) {
        this.service.updatePerson(this.role.id, person.id, !person.pivot.reserve).subscribe(
            role => {
                this.role = role;
                this.personIDs = this.role.people.map( p => p.id );
            }
        );
    }

}
