import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/app/api/auth.service';
import { Event, EventPerson } from 'src/app/api/models/event.model';
import { Role } from 'src/app/api/models/role.model';
import { Team } from 'src/app/api/models/team.model';
import { EventUpdatesService } from 'src/app/websockets/event-updates.service';

@Component({
    selector: 'app-rota-grid',
    templateUrl: './rota-grid.component.html',
    styleUrls: ['./rota-grid.component.css']
})
export class RotaGridComponent implements OnInit {

    _events: Event[] = [];

    teams: Team[] = [];
    roles: Role[] = [];

    editing = false;

    @Input() filteredTeams: string[] = [];
    @Input()
    set events(events: Event[]) {
        this._events = events;
        this.setupGrid();
    }
    get events() {
        return this._events;
    }

    @Output() deleted = new EventEmitter<Event>();

    constructor(private api: ApiService, private authService: AuthService, private updatesService: EventUpdatesService) { }

    ngOnInit(): void {
        this.setupGrid();
        this.updatesService.eventUpdated.subscribe(event => {
            this.events = this.events.map(e => e.id === event.id ? event : e);
            this.setupGrid();
        });
        this.updatesService.eventDeleted.subscribe(eventID => {
            this.events = this.events.filter(e => e.id !== eventID);
            this.setupGrid();
        });
    }

    setupGrid() {
        this.teams = this.events
                            .flatMap(event => event.teams.map(eventTeam => eventTeam.team))
                            .filter((team, index, array) => array.findIndex(t => t.id === team.id) === index)
                            .filter(team => !this.filteredTeams.includes(team.id))
        this.roles = this.teams.flatMap(team => team.roles)
    }

    roleIsInSingleRoleTeam(role: Role): boolean {
        return this.teams.find(team => team.id === role.team_id).single_role
    }

    peopleForEventRole(event: Event, role: Role): EventPerson[] {
        const eventTeamRole = event.teams.flatMap(team => team.roles).find(eventTeamRole => eventTeamRole.role_id === role.id)

        if (eventTeamRole === undefined) {
            return [];
        }

        return event.people.filter(eventPerson => eventPerson.event_team_role_id === eventTeamRole.id)
    }

    eventHasTeam(event: Event, teamID: string) {
        return event.teams.filter(eventTeam => eventTeam.team_id === teamID).length > 0
    }

    canEdit() {
        if (!this.api.isAuthenticated || this.authService.me === null) {
            return false;
        }

        if (this.authService.isAdmin) {
            return true;
        }

        if (this.teams.map(team => team.id).filter(teamID => this.authService.adminTeams.includes(teamID)).length > 0) {
            return true;
        }

        const roleIDs = this.roles.map(role => role.id)

        if (this.authService.me.roles.map(role => role.id).filter(roleID => roleIDs.includes(roleID)).length > 0) {
            return true;
        }

        return false;
    }

    changedPerson(event: Event) {
        this._events = this._events.map(e => { return (e.id === event.id) ? event : e });
    }

}
