import { Component, OnInit } from '@angular/core';
import { EventTemplatesService } from 'src/app/api/event-templates.service';
import { EventTemplate } from 'src/app/api/models/eventtemplate.model';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/api/auth.service';

@Component({
    selector: 'app-events-page',
    templateUrl: './events-page.component.html'
})
export class EventsPageComponent implements OnInit {

    templates: EventTemplate[] = [];
    selectedTemplateID?: string;
    popover: NgbPopover;

    constructor(private service: EventTemplatesService, private router: Router, public authService: AuthService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && event.url !== '/events') {
                this.selectedTemplateID = event.url.split('/').pop();
            } else if (event instanceof NavigationEnd && event.url === '/events') {
                this.selectedTemplateID = null;
            }
        });

        this.service.eventTemplatesUpdated.subscribe(eventTemplates => { this.templates = eventTemplates; });
    }

    ngOnInit() {
        this.service.updateList();
    }

    addTemplate(p: NgbPopover) {
        this.popover = p;
    }

    cancelAddingTemplate() {
        this.popover.close();
    }

    addedTemplate(template: EventTemplate) {
        this.popover.close();
        this.templates.push(template);
        this.templates.sort((a, b) => (a.name > b.name ) ? 1 : -1);
        this.selectTemplate(template);
    }

    selectTemplate(template: EventTemplate) {
        this.selectedTemplateID = template.id;
        this.router.navigate(['events/templates', template.id]);
    }

}
