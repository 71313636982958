<div class="d-flex flex-wrap align-items-start">
    <div class="month border rounded shadow-sm bg-light d-inline-block m-1 mb-4" *ngFor="let month of dates">
        <table class="text-center">
            <thead>
                <tr>
                    <td colspan="7" class="month p-2">
                        {{ month.name }} {{ month.year }}
                    </td>
                </tr>
                <tr>
                    <td class="date">M</td>
                    <td class="date">Tu</td>
                    <td class="date">W</td>
                    <td class="date">Th</td>
                    <td class="date">F</td>
                    <td class="date text-secondary">Sa</td>
                    <td class="date text-secondary">Su</td>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let week of month.weeks">
                    <tr>
                        <td class="date bg-white" *ngFor="let _ of [].constructor(week.startOffset)"></td>
                        <td class="date bg-white" [ngClass]="{'text-secondary': day.date.day() === 6 || day.date.day() === 0}" *ngFor="let day of week.days">
                            <span class="date-event text-info bg-light d-inline-block border rounded-circle" *ngIf="day.events.length > 0"><a href="#" (click)="selectDay(day, p)" #p="ngbPopover" [ngbPopover]="eventPickerPopover">{{ day.date.date() }}</a></span>
                            <span *ngIf="day.events.length === 0">{{ day.date.date() }}</span>
                        </td>
                        <td class="date bg-white" *ngFor="let _ of [].constructor(7 - week.days.length - week.startOffset)"></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<ng-template #eventPickerPopover>
    <ul class="list-unstyled mb-0">
        <li *ngFor="let event of selectedDay.events">
            <button class="btn btn-sm mt-0 mb-0" (click)="showEvent(eventModal, event)">{{ event.name }} {{ event.display_times }}</button>
        </li>
    </ul>
</ng-template>

<ng-template #eventModal>
    <app-rota-event [event]="selectedEvent" [filteredTeams]="filteredTeams" [inList]="false" (deleted)="deletedEvent($event)"></app-rota-event>
</ng-template>
