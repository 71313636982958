<div class="container h-100 overflow-hidden">
    <div class="row h-100 overflow-hidden">
        <div class="col mh-100 overflow-auto" *ngIf="logs">
            <div class="d-flex align-items-start">
                <ngb-pagination (pageChange)="pageChanged($event)" [page]="logs.current_page" [pageSize]="logs.per_page" [collectionSize]="logs.total" [maxSize]="8" [directionLinks]="true" [rotate]="true" size="sm"></ngb-pagination>
                <span class="ms-1 me-1">Detail:</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn" [ngClass]="{'btn-primary': verbosity === 1, 'btn-outline-primary': verbosity !== 1 }" (click)="setVerbosity(1)">Low</button>
                    <button type="button" class="btn" [ngClass]="{'btn-primary': verbosity === 2, 'btn-outline-primary': verbosity !== 2 }" (click)="setVerbosity(2)">Medium</button>
                    <button type="button" class="btn" [ngClass]="{'btn-primary': verbosity === 3, 'btn-outline-primary': verbosity !== 3 }" (click)="setVerbosity(3)">High</button>
                </div>
            </div>

            <ul class="list-group">
                <li class="list-group-item" [ngClass]="{'fw-bold': logEntry.type === LogEntryType.error}" *ngFor="let logEntry of logs.data">
                    <small>{{ logEntry.created_at | date:"dd MMM yyyy HH:mm" }} <span *ngIf="logEntry.person">{{ logEntry.person.first_name }} {{ logEntry.person.last_name }}</span></small> {{ logEntry.message }}
                </li>
            </ul>
        </div>
    </div>
</div>
