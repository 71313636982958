import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogsPageComponent } from './logs-page/logs-page.component';
import { NgbPaginationModule, NgbPopoverModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationPageComponent } from './organisation-page/organisation-page.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';



@NgModule({
    declarations: [LogsPageComponent, OrganisationPageComponent],
    imports: [
        CommonModule,
        NgbPaginationModule,
        NgbPopoverModule,
        NgbDropdownModule,
        CommonComponentsModule,
        FormsModule,
        RouterModule
    ]
})
export class OrganisationModule { }
