import { Component, OnInit } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { OrganisationService } from '../api/organisation.service';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styles: ['.login-card: { min-width: 300px; }']
})
export class LoginPageComponent implements OnInit {

    loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
    });

    constructor(
        private auth: AuthService,
        public organisationService: OrganisationService,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit() {
    }

    login() {
        this.auth.login(this.loginForm.value.email, this.loginForm.value.password);
    }
}
