import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/api/models/person.model';
import { PeopleService } from 'src/app/api/people.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { PeopleUploaderComponent } from '../people-uploader/people-uploader.component';
import { AuthService } from 'src/app/api/auth.service';

@Component({
    selector: 'app-people-page',
    templateUrl: './people-page.component.html'
})
export class PeoplePageComponent implements OnInit {

    people: Person[] = [];
    filteredPeople: Person[] = [];
    addPersonPopover: NgbPopover;
    selectedPersonID?: string = null;

    filterText = '';

    page = 1;
    peoplePerPage = 10;

    constructor(public service: PeopleService, private modalService: NgbModal, private router: Router, public authService: AuthService) {
        this.service.peopleUpdated.subscribe(people => {
            this.people = people;
            this.updateFilter();
            if (this.selectedPersonID !== null) {
                setTimeout(() => {
                    this.scrollToPerson(this.selectedPersonID);
                }, 0.1);
            }
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && event.url !== '/people') {
                this.selectedPersonID = event.url.split('/').pop();
                if (this.selectedPersonID !== null) {
                    setTimeout(() => {
                        this.scrollToPerson(this.selectedPersonID);
                    }, 0.1);
                }
            } else if (event instanceof NavigationEnd && event.url === '/people') {
                this.selectedPersonID = null;
            }
        });
    }

    ngOnInit() {
        this.service.updateList();
    }

    openAddPersonPopover(popover) {
        this.addPersonPopover = popover;
    }

    addedPerson(person) {
        this.addPersonPopover.close();
        this.selectPerson(person);
    }

    uploadPeople() {
        const modal = this.modalService.open(PeopleUploaderComponent);
        modal.componentInstance.finishedUploading.subscribe((success) => {
            modal.close();
        });
    }

    selectPerson(person: Person) {
        this.selectedPersonID = person.id;
        this.router.navigate(['people', person.id]);
        this.scrollToPerson(this.selectedPersonID);
    }

    scrollToPerson(personID: string) {
        const element = document.getElementById('person-' + personID);

        if (element === null) {
            return;
        }

        const rect = element.getBoundingClientRect();
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

        if (rect.bottom < 0 || rect.top - viewHeight >= 0) {
            setTimeout(() => {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
            }, 0);
        }
    }

    updateFilter() {
        if (this.filterText === '') {
            this.filteredPeople = this.people;
        }

        const searchTerm = this.filterText.toLocaleLowerCase();

        this.filteredPeople = this.people.filter( person => {

            const firstName = person.first_name.toLocaleLowerCase();
            const lastName = person.last_name.toLocaleLowerCase();

            if (searchTerm.includes(' ')) {
                const words = searchTerm.split(' ').filter( word => word.length > 0 );

                let match = true;

                words.forEach(word => {
                    if (!firstName.includes(word) && !lastName.includes(word)) {
                        match = false;
                    }
                });

                return match;

            } else if (firstName.includes(searchTerm) || lastName.includes(searchTerm)) {
                return true;
            }

            return false;
        });
    }
}
