<div class="border rounded shadow-sm bg-white" *ngIf="team != null">
    <div class="border-bottom px-3 pt-3 pb-1 bg-light rounded-top">
        <h3 class="p-0">{{ team.name }}</h3>
    </div>
    <div class="px-3 pt-2 pb-0">
        <span class="badge bg-info" *ngIf="team.single_role">Single role</span>
    </div>
    <div class="p-3">
        <button class="btn btn-warning btn-sm shadow-sm me-1" *ngIf="authService.isAdminForTeam(team.id)" (click)="openPopover(p)" #p="ngbPopover" [ngbPopover]="editTeamTemplate" placement="bottom">Edit</button>
        <button class="btn btn-danger btn-sm shadow-sm" *ngIf="authService.isAdmin" (click)="deleteTeam()">Delete</button>
    </div>
    <div class="p-3 border-top">
        <p>
            People:
            <button type="button" class="btn btn-sm btn-success shadow-sm ms-2" placement="right" popoverTitle="Select people" [ngbPopover]="addPeople" *ngIf="authService.isAdminForTeam(team.id)">Add people</button>
        </p>
        <table *ngIf="team.people !== null" class="table table-sm">
            <thead>
                <tr>
                    <td>Name</td>
                    <td style="text-align: center;" *ngIf="team.single_role">Reserve</td>
                    <td style="text-align: center;">Team Manager</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let person of team.people">
                    <td>
                        <a href="#" class="me-1" *ngIf="(authService.isAdminForTeam(team.id)) && !person.pivot.is_admin" (click)="removePerson(person)"><app-svg-icon icon="delete"></app-svg-icon></a><a [routerLink]="['/people', person.id]">{{ person.first_name }} {{ person.last_name }}</a><br>
                        <span *ngIf="!team.single_role">
                            <a class="badge bg-info me-1" *ngFor="let role of person.roles" [routerLink]="['/roles', role.id]">{{ role.name }} {{ role.pivot.reserve ? '(R)' : '' }}</a>
                            <button class="btn badge btn-success shadow-sm" placement="right" *ngIf="authService.isAdminForTeam(team.id) && person.roles.length !== team.roles.length" popoverTitle="Select role" [ngbPopover]="selectRoleTemplate" #p="ngbPopover" (click)="openAddRoleToPersonPopover(p, person)"><app-svg-icon icon="add" color="white"></app-svg-icon> Add role</button>
                        </span>
                    </td>
                    <td style="text-align: center;" *ngIf="team.single_role">
                        <input type="checkbox" (click)="toggleReserve(person)" [checked]="person.roles[0].pivot.reserve" [disabled]="!authService.isAdminForTeam(team.id)">
                    </td>
                    <td style="text-align: center;">
                        <input type="checkbox" (click)="toggleAdmin(person)" [checked]="person.pivot.is_admin || person.is_admin" [disabled]="person.is_admin || person.user_id === null || authService.me.id === person.id || !(authService.isAdminForTeam(team.id))">
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="!team.single_role">
            <p>
                Roles:
                <button type="button" class="btn btn-sm btn-success shadow-sm ms-2" placement="right" #p="ngbPopover" (click)="openPopover(p)" [ngbPopover]="addRoleTemplate" *ngIf="authService.isAdminForTeam(team.id)">New role</button>
            </p>
            <table *ngIf="team.roles !== null" class="table table-sm">
                <tbody>
                    <tr *ngFor="let role of team.roles">
                        <td><a [routerLink]="['/roles', role.id]">{{ role.name }}</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #addPeople><app-person-selector (selected)="addPerson($event)" [disallowedPersonIDs]="personIDs"></app-person-selector></ng-template>

<ng-template #addRoleTemplate>
    <app-edit-role [team]="team" (roleSaved)="addedRole($event)"></app-edit-role>
</ng-template>

<ng-template #editTeamTemplate>
    <app-edit-team [team]="team" (teamSaved)="teamEdited($event)"></app-edit-team>
</ng-template>

<ng-template #selectRoleTemplate>
    <app-role-selector [roles]="team.roles" (selected)="addRoleToPerson($event)"></app-role-selector>
</ng-template>
