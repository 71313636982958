import { Injectable } from '@angular/core';
import { ApiModule } from './api.module';
import { ApiService } from './api.service';
import { PaginatedResource } from './interfaces/paginatedresource';
import { LogEntry } from './models/logentry';

@Injectable({
    providedIn: ApiModule
})
export class LogsService {

    constructor(private api: ApiService) { }

    listLogs(page = 1, verbosity = 1, type?: number) {
        let resource = 'logs?page=' + page + '&verbosity=' + verbosity;

        if (type !== undefined) {
            resource += '&type=' + type;
        }
        return this.api.get<PaginatedResource<LogEntry>>(resource);
    }
}
