import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HintService {

    visible = false;

    text = '';

    constructor(private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.visible = false;
            }
        });
    }

    showHint(text: string) {
        this.text = text;
        this.visible = true;
    }

    hideHint() {
        this.visible = false;
    }
}
