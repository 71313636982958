<a class="me-2" href="#" (click)="editPerson(p)" triggers="manual" #p="ngbPopover" [ngbPopover]="selectPerson" placement="right" popoverTitle="Select person" *ngIf="editing && canEdit">
    <app-svg-icon [icon]="eventPerson === undefined ? 'add' : 'pencil'" [color]="eventPerson === undefined ? 'success' : 'warning'"></app-svg-icon>
</a>
<a class="me-1" href="#" (click)="removePerson()" *ngIf="editing && canEdit && eventPerson !== undefined">
    <app-svg-icon icon="delete" color="danger"></app-svg-icon>
</a>
<ng-container *ngIf="eventPerson !== undefined">
    {{ eventPerson.person.display_name }}
    <app-svg-icon class="ms-1" icon="alert" color="warning" tooltip="This person is in multiple roles for this event" *ngIf="eventPerson.issues | hasFlag: IssuesEnum.Conflict"></app-svg-icon>
    <app-svg-icon class="ms-1" icon="alert" color="danger" tooltip="This person is unavailable for this event" *ngIf="eventPerson.issues | hasFlag: IssuesEnum.Unavailable"></app-svg-icon>
</ng-container>

<ng-template #selectPerson><app-person-selector (selected)="selectedPerson($event)" [people]="role.people" [availabilityStart]="event.starts_at" [availabilityEnd]="event.ends_at"></app-person-selector></ng-template>
