import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-rota-passcode-entry',
    templateUrl: './rota-passcode-entry.component.html'
})
export class RotaPasscodeEntryComponent implements OnInit {

    @Input() passcode = '';

    @Output() passcodeEntered = new EventEmitter<String>();

    constructor() { }

    ngOnInit(): void {
    }

    submitPasscode() {
        this.passcodeEntered.emit(this.passcode);
    }

}
