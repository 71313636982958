import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Person } from 'src/app/api/models/person.model';
import { Role } from 'src/app/api/models/role.model';
import { ApiService } from 'src/app/api/api.service';
import { Event, EventPerson, EventPersonIssues } from 'src/app/api/models/event.model';
import { RotaService } from 'src/app/api/rota.service';
import { RolesService } from 'src/app/api/roles.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/api/auth.service';

@Component({
    selector: 'app-rota-person',
    templateUrl: './rota-person.component.html',
    styles: [
        '.no-person { border-bottom: 1px dotted black; width: 80%; display: inline-block; }'
    ]
})
export class RotaPersonComponent implements OnInit {

    @Input() event: Event;
    @Input() eventPerson?: EventPerson;
    @Input() role: Role;
    @Input() editing: boolean;

    @Output() changed = new EventEmitter<Event>();

    IssuesEnum = EventPersonIssues;

    popover: NgbPopover;

    canEdit: boolean;

    constructor(
        private service: RotaService,
        private rolesService: RolesService,
        private authService: AuthService) {
    }

    ngOnInit(): void {
        if (this.authService.me === null) {
            this.canEdit = false;
        } else if (this.authService.isAdminForTeam(this.role.team_id)) {
            this.canEdit = true;
        } else if (this.authService.me.roles.map( role => role.id).includes(this.role.id)) {
            this.canEdit = true;
        } else {
            this.canEdit = false;
        }
    }

    selectedPerson(person: Person) {
        if (this.eventPerson !== undefined) {
            this.service.deletePerson(this.eventPerson.id).subscribe( () => {
                this.eventPerson = undefined;
                this.service.addPerson(this.event.id, this.role.id, person.id).subscribe( event => {
                    const eventTeamRole = event
                        .teams
                        .find(et => et.team_id === this.role.team_id)
                        .roles
                        .find(etr => etr.role_id === this.role.id);
                    this.eventPerson = event.people.find(ep =>
                        ep.person_id === person.id && ep.event_team_role_id === eventTeamRole.id
                    );
                    this.changed.emit(event);
                    this.popover.close();
                });
            });
        } else {
            this.service.addPerson(this.event.id, this.role.id, person.id).subscribe( event => {
                const eventTeamRole = event
                    .teams
                    .find(et => et.team_id === this.role.team_id)
                    .roles
                    .find(etr => etr.role_id === this.role.id);
                this.eventPerson = event.people.find(ep =>
                    ep.person_id === person.id && ep.event_team_role_id === eventTeamRole.id
                );
                this.changed.emit(event);
                this.popover.close();
            });
        }
    }

    editPerson(popover: NgbPopover) {
        this.popover = popover;
        this.rolesService.getRole(this.role.id).subscribe( role => {
            this.role = role;
            popover.open();
        });

        return false;
    }

    removePerson() {
        this.service.deletePerson(this.eventPerson.id).subscribe( event => {
            this.eventPerson = undefined;
            this.changed.emit(event);
        });
        return false;
    }

}
