import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {

    @Input() startTime: string;
    @Input() endTime: string;
    @Output() startTimeUpdated = new EventEmitter<string>();
    @Output() endTimeUpdated = new EventEmitter<string>();

    times = [
        '00:00','00:15','00:30','00:45','01:00','01:15','01:30','01:45','02:00','02:15','02:30','02:45','03:00','03:15','03:30','03:45',
        '04:00','04:15','04:30','04:45','05:00','05:15','05:30','05:45','06:00','06:15','06:30','06:45','07:00','07:15','07:30','07:45',
        '08:00','08:15','08:30','08:45','09:00','09:15','09:30','09:45','10:00','10:15','10:30','10:45','11:00','11:15','11:30','11:45',
        '12:00','12:15','12:30','12:45','13:00','13:15','13:30','13:45','14:00','14:15','14:30','14:45','15:00','15:15','15:30','15:45',
        '16:00','16:15','16:30','16:45','17:00','17:15','17:30','17:45','18:00','18:15','18:30','18:45','19:00','19:15','19:30','19:45',
        '20:00','20:15','20:30','20:45','21:00','21:15','21:30','21:45','22:00','22:15','22:30','22:45','23:00','23:15','23:30','23:45'
    ];

    currentPopoverField = 'start';
    activePopover: NgbPopover;

    constructor() { }

    ngOnInit(): void {
    }

    setPopover(popover: NgbPopover, name: string) {
        this.activePopover = popover;
        this.currentPopoverField = name;

        setTimeout(() => {
            const currentTime = (name === 'start' ? this.startTime : this.endTime).replace(/[^0-9]/g, '');
            const element = document.getElementById('time-' + currentTime);

            if (element === null) {
                return;
            }

            const rect = element.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

            if (rect.bottom < 0 || rect.top - viewHeight >= 0) {
                setTimeout(() => {
                    element.scrollIntoView({block: 'center'});
                }, 0);
            }
        }, 0.1);
    }

    changedStartTime() {
        this.startTime = this.parseTime(this.startTime);
        this.startTimeUpdated.emit(this.startTime);

        const numericStartTime = Number(this.startTime.replace(':', ''));
        const numericEndTime = Number(this.endTime.replace(':', ''));

        if (numericEndTime < numericStartTime) {
            if (numericStartTime >= 2300) {
                this.endTime = this.parseTime(String(numericStartTime));
            } else {
                this.endTime = this.parseTime(String(numericStartTime + 100));
            }
            this.endTimeUpdated.emit(this.endTime);
        }
    }

    changedEndTime() {
        this.endTime = this.parseTime(this.endTime);
        this.endTimeUpdated.emit(this.endTime);

        const numericStartTime = Number(this.startTime.replace(':', ''));
        const numericEndTime = Number(this.endTime.replace(':', ''));

        if (numericEndTime < numericStartTime) {
            this.startTime = this.parseTime(String(numericEndTime - 100));
            this.startTimeUpdated.emit(this.startTime);
        }
    }

    parseTime(time: string) {
        const isPM = time.toLocaleLowerCase().includes('pm');

        time = time.replace(/[^0-9]/g, '').substr(0, 4);

        let hours = 0;
        let minutes = 0;

        switch (time.length) {
            case 0:
                break;
            case 1:
                hours = Number(time);
                break;
            case 2:
                if (Number(time) <= 23) {
                    hours = Number(time);
                }
                break;
            case 3:
                hours = Number(time.substr(0, 1));
                minutes = Number(time.substr(1, 2));
                break;
            case 4:
                hours = Number(time.substr(0, 2));
                minutes = Number(time.substr(2, 2));
                break;
        }

        if (isPM) {
            hours += 12;
        }

        while (hours > 23) {
            hours -= 24;
        }

        while (minutes > 59) {
            minutes -= 60;
        }

        return '' + (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
    }

    setTime(time: string) {
        this.activePopover.close();
        if (this.currentPopoverField === 'start') {
            this.startTime = time;
            this.changedStartTime();
        } else {
            this.endTime = time;
            this.changedEndTime();
        }
    }

}
