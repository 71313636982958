import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Role } from 'src/app/api/models/role.model';
import { RolesService } from 'src/app/api/roles.service';
import { Team } from 'src/app/api/models/team.model';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-edit-role',
    templateUrl: './edit-role.component.html'
})
export class EditRoleComponent implements OnInit, AfterViewInit {

    roleForm = this.formBuilder.group({
        name: ['', Validators.required]
    });

    buttonText = 'Add';

    @Input() team?: Team = null;
    @Input() role?: Role = null;

    @Output() roleSaved = new EventEmitter<Role>();

    @ViewChild('name') nameElement: ElementRef;

    constructor(
        private service: RolesService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        if (this.role !== null) {
            this.roleForm.setValue({name: this.role.name});
            this.buttonText = 'Save';
        }
    }

    ngAfterViewInit(): void {
        this.nameElement.nativeElement.focus();
    }

    save() {
        if (this.role === null && this.team !== null) {
            this.service.addRole(this.roleForm.get('name').value, this.team).subscribe(
                role => {
                    if (role !== null) {
                        this.roleSaved.emit(role);
                    }
                }
            );
        } else {
            this.role = Object.assign(this.role, this.roleForm.value);
            this.service.updateRole(this.role).subscribe(
                role => {
                    if (role !== null) {
                        this.roleSaved.emit(role);
                    }
                }
            );
        }
    }

}
