<form class="form" [formGroup]="teamForm">
    <div class="input-group input-group-sm">
        <input type="text" class="form-control" #name placeholder="Team name" formControlName="name">
        <button class="btn btn-primary btn-sm" (click)="save()" [disabled]="!teamForm.valid">{{ buttonText }}</button>
    </div>
    <div class="form-check mt-2 mb-1" *ngIf="team === null || team.single_role || team.roles.length < 2">
        <input type="checkbox" class="form-check-input" id="singleRoleCheck" formControlName="single_role">
        <label class="form-check-label" for="singleRoleCheck">Single role team</label>
    </div>
</form>
