import { Injectable, EventEmitter } from '@angular/core';
import { ApiModule } from './api.module';
import { ApiService } from './api.service';
import { Person } from './models/person.model';
import { Observable } from 'rxjs';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { AddPeopleReturn } from './interfaces/addpeoplereturn';
import { ArrayType } from '@angular/compiler';
import { Event } from './models/event.model';

@Injectable({
    providedIn: ApiModule
})
export class PeopleService {

    constructor(private api: ApiService) { }

    people?: Person[];

    duplicatedDisplayNames: string[] = [];

    peopleUpdated = new EventEmitter<Person[]>();

    updateList() {
        this.people = null;
        this.api.get<Person[]>('people')
            .subscribe(data => {
                this.people = data;
                this.duplicatedDisplayNames = this.people
                    .filter(person =>
                        this.people.filter(p =>
                            p.display_name === person.display_name
                        ).length > 1
                    )
                    .map(person => person.display_name );
                this.peopleUpdated.emit(this.people);
            }, error => {
                this.peopleUpdated.emit([]);
            });
    }

    addPeople(people: Person[], sendWelcomeEmail: boolean) {
        return new Observable<AddPeopleReturn>(subscriber => {
            return this.api.post<AddPeopleReturn>('people', {people, send_welcome_email: sendWelcomeEmail})
                .subscribe(data => {
                    this.updateList();
                    subscriber.next(data);
                    subscriber.complete();
                }, error => {
                    subscriber.next(null);
                    subscriber.complete();
                });
        });
    }

    getPerson(id: string) {
        return this.api.get<Person>('people/' + id);
    }

    updatePerson(person: Person) {
        return new Observable<Person>(subscriber => {
            return this.api.put<Person>('people/' + person.id, person)
                .subscribe(data => {
                    this.updateList();
                    subscriber.next(data);
                    subscriber.complete();
                }, error => {
                    subscriber.next(null);
                    subscriber.complete();
                });
        });
    }

    deletePerson(personID: string) {
        return this.api.delete('people/' + personID);
    }

    sendWelcomeEmail(person: Person) {
        return this.api.post<Person>('people/' + person.id + '/send-welcome-email', {});
    }

    getUpcomingEvents(person: Person) {
        return this.api.get<Event[]>('people/' + person.id + '/events');
    }
}
