<div class="event" [ngClass]="{'border rounded shadow-sm bg-white mb-4': inList}">
    <div class="d-flex flex-wrap align-items-center border-bottom rounded-top p-1 bg-light">
        <h4 class="mb-1 mt-1 ms-2 me-1">
            {{ event.name }}
        </h4>
        <span class="mb-0 ms-2">{{ event.starts_at | date:"EEE, dd MMM HH:mm" }} - {{ event.ends_at | date:"HH:mm" }}</span>
        <span class="mb-0 ms-2 flex-fill">
            <button *ngIf="canEditEvent() && event.teams.length > 0 && !editing" class="btn btn-warning btn-sm shadow-sm me-1" (click)="toggleEditing()">
                <app-svg-icon icon="pencil" color="black"></app-svg-icon>
                Edit Rota
            </button>
            <button *ngIf="api.isAuthenticated && editing" class="btn btn-success btn-sm shadow-sm me-1" (click)="toggleEditing()">
                Done
            </button>
            <button *ngIf="api.isAuthenticated && authService.isAdmin" class="btn btn-danger btn-sm shadow-sm me-1" (click)="deleteEvent()">
                <app-svg-icon icon="delete" color="white"></app-svg-icon>
                Delete
            </button>
            <button *ngIf="(api.isAuthenticated && authService.isAdmin) || event.notes !== ''" class="btn btn-sm btn-text me-1" [ngbPopover]="notesTemplate" (click)="editEventNotes()">
                <app-svg-icon icon="document" tooltip="Notes" [hasDot]="event.notes !== '' && api.isAuthenticated && authService.isAdmin"></app-svg-icon>
            </button>
        </span>
        <button *ngIf="!inList" class="btn btn-outline-primary btn-sm float-end me-1" (click)="closeModal()">Close</button>
    </div>
    <div class="m-2" *ngIf="event.teams.length === 0 && api.isAuthenticated">
        <h5>No teams for this event</h5>
    </div>
    <div class="d-flex flex-wrap m-2" *ngIf="event.teams.length > 0">
        <ng-container *ngFor="let team of event.teams">
            <div class="border rounded-top mb-2 me-2 event-team align-self-start shadow-sm" *ngIf="!filteredTeams.includes(team.team_id)">
                <div class="d-flex flex-wrap align-items-center border-bottom rounded-top justify-content-between bg-light">
                    <h5 class="p-2 me-1 mb-0">{{ team.name }}</h5>
                    <button *ngIf="authService.isAdminForTeam(team.team_id) || team.notes !== ''" class="btn btn-sm btn-text" [ngbPopover]="teamNotesTemplate" (click)="editTeamNotes(team)">
                        <app-svg-icon icon="document" [hasDot]="team.notes !== ''"></app-svg-icon>
                    </button>
                </div>
                <ul class="list-group list-group-flush" *ngIf="!editing">
                    <ng-container *ngFor="let eventRole of team.roles">
                        <li class="rota-people-list list-group-item p-1 text-muted" *ngIf="(team.team === null && team.roles.length === 0) || (team.team !== null && !team.team.single_role)">
                            {{ eventRole.name }}
                        </li>
                        <li class="rota-people-list list-group-item p-1 ps-3" *ngFor="let person of peopleForEventRole(eventRole)">
                            <app-rota-person [event]="event" [eventPerson]="person" [role]="eventRole.role" [editing]="editing" (changed)="changed($event)"></app-rota-person>
                        </li>
                    </ng-container>
                </ul>
                <ul class="list-group list-group-flush" *ngIf="editing">
                    <ng-container *ngFor="let role of team.team.roles">
                        <li class="rota-people-list list-group-item p-1 text-muted" *ngIf="!team.team.single_role && (peopleForRole(role).length > 0 || canEditRole(role))">
                            {{ role.name }}
                        </li>
                        <li class="rota-people-list list-group-item p-1 ps-3" *ngFor="let person of peopleForRole(role)">
                            <app-rota-person [event]="event" [eventPerson]="person" [role]="role" [editing]="editing" (changed)="changed($event)"></app-rota-person>
                        </li>
                        <li class="rota-people-list list-group-item p-1 ps-3" *ngIf="canEditRole(role)">
                            <app-rota-person [event]="event" [role]="role" editing="true" (changed)="changed($event)"></app-rota-person>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #notesTemplate><app-notes [event]="event" [canEdit]="selectedNotesEditable" (didEdit)="notesChanged($event)"></app-notes></ng-template>
<ng-template #teamNotesTemplate><app-notes [eventTeam]="selectedNotesTeam" [canEdit]="selectedNotesEditable" (didEdit)="notesChanged($event)"></app-notes></ng-template>
