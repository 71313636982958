import { Component, OnInit } from '@angular/core';
import { OrganisationService } from 'src/app/api/organisation.service';
import { Organisation } from 'src/app/api/models/organisation.model';
import { ToastService } from 'src/app/common-components/toast.service';
import { AuthService } from 'src/app/api/auth.service';

@Component({
    selector: 'app-organisation-page',
    templateUrl: './organisation-page.component.html'
})
export class OrganisationPageComponent implements OnInit {

    organisation: Organisation;

    hasChanges = false;

    reminderMultiplier = 1;
    reminderNumber = 1;

    constructor(private service: OrganisationService, private toastService: ToastService, public authService: AuthService) { }

    ngOnInit(): void {
        this.service.getOrganisation().subscribe(organisation => {
            this.organisation = organisation;
            this.reminderNumber = organisation.preferences.remind_hours_before;

            if (this.reminderNumber % 24 === 0 && this.reminderNumber > 24) {
                this.reminderNumber = this.reminderNumber / 24;
                this.reminderMultiplier = 24;
            }

            if (window.location.hash === '#subscribe') {
                this.authService.redirectToken().subscribe(token => {
                    const tld = window.location.host.split('.').pop();
                    window.location.href = '//plan2gether.' + tld + '/login/redirect/' + token.id + '?url=' + encodeURIComponent('/payments/manage-subscription/' + organisation.id);
                });
            }
        });
    }

    savePreferences() {
        this.organisation.preferences.remind_hours_before = this.reminderNumber * this.reminderMultiplier;

        this.service.updatePreferences(this.organisation.preferences).subscribe(organisation => {
            this.organisation = organisation;
            this.toastService.showSuccess('Preferences updated');
            this.hasChanges = false;
        });
    }

    setReminderMultiplier(multiplier: number) {
        this.reminderMultiplier = multiplier;

        if (multiplier === 24) {
            this.reminderNumber = Math.ceil(this.reminderNumber / multiplier);
        } else {
            this.reminderNumber = this.reminderNumber * 24;
        }
    }

    manageSubscription() {
        this.authService.redirectToken().subscribe(token => {
            const tld = window.location.host.split('.').pop();
            window.location.href = '//plan2gether.' + tld + '/login/redirect/' + token.id + '?url=' + encodeURIComponent('/payments/manage-subscription/' + this.organisation.id);
        });
    }

    buyCredits() {
        this.authService.redirectToken().subscribe(token => {
            const tld = window.location.host.split('.').pop();
            window.location.href = '//plan2gether.' + tld + '/login/redirect/' + token.id + '?url=' + encodeURIComponent('/payments/sms-credits/' + this.organisation.id);
        });
    }
}
