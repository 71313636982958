import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/api/models/person.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PeopleService } from 'src/app/api/people.service';
import { NgbPopover, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/common-components/toast.service';
import { AuthService } from 'src/app/api/auth.service';
import { ConfirmationService } from 'src/app/common-components/confirmation.service';
import { UnavailabilitiesService } from 'src/app/api/unavailabilities.service';
import { Unavailability } from 'src/app/api/models/unavailability.model';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Event } from 'src/app/api/models/event.model';
import { EventsService } from 'src/app/api/events.service';

@Component({
    selector: 'app-person-info',
    templateUrl: './person-info.component.html'
})
export class PersonInfoComponent implements OnInit {

    person: Person;
    userPassword: string;
    popover: NgbPopover;
    unavailabilities: Unavailability[] = [];
    upcomingEvents: Event[] = [];
    selectedEvent: Event;

    unavailabilityStart = {year: 0, month: 0, day: 0};
    unavailabilityEnd = {year: 0, month: 0, day: 0};

    constructor(
        private route: ActivatedRoute,
        public service: PeopleService,
        private toastService: ToastService,
        public authService: AuthService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private unavailabilitiesService: UnavailabilitiesService,
        private eventsService: EventsService,
        private modalService: NgbModal
    ) {
        this.route.params.subscribe(
            params => {
                this.service.getPerson(params['id'])
                    .subscribe(
                        p => {
                            this.person = p;
                            this.unavailabilitiesService.listUnavailabilities(this.person.id).subscribe(
                                u => {
                                    this.unavailabilities = u;
                                }
                            );
                            this.service.getUpcomingEvents(this.person).subscribe(
                                events => {
                                    this.upcomingEvents = events;
                                }
                            );
                        }
                    );
            }
        );
    }

    ngOnInit() {
        const today = moment();
        const nextWeek = moment().add(7, 'day');
        this.unavailabilityStart = {year: today.year(), month: today.month()+1, day: today.date()};
        this.unavailabilityEnd = {year: nextWeek.year(), month: nextWeek.month()+1, day: nextWeek.date()};
    }

    openPopover(popover: NgbPopover) {
        this.popover = popover;
    }

    sendWelcomeEmail() {
        this.service.sendWelcomeEmail(this.person).subscribe(_ => {
            this.person.welcome_email_sent_at = (new Date()).toISOString();
            this.toastService.showSuccess('Sent welcome email to ' + this.person.first_name + ' ' + this.person.last_name);
        })
    }

    editedPerson(person) {
        if (person !== null) {
            this.popover.close();
            this.person = person;
        }
    }

    userProfile() {
        this.authService.redirectToken().subscribe(token => {
            window.location.href = environment.loginRedirectURL + token.id;
        })
    }

    deletePerson() {
        this.confirmationService.confirm('permanently delete ' + this.person.first_name + ' ' + this.person.last_name + '? This cannot be undone.', 'Delete', answer => {
            if (answer) {
                this.service.deletePerson(this.person.id).subscribe(data => {
                    this.service.updateList();
                    this.toastService.showSuccess(this.person.first_name + ' ' + this.person.last_name + ' deleted.');
                    this.router.navigate(['people']);
                });
            }
        });
    }

    addUnavailability() {
        const startDate = '' + this.unavailabilityStart.year + '-'
        + (this.unavailabilityStart.month < 10 ? '0' : '') + this.unavailabilityStart.month + '-'
        + (this.unavailabilityStart.day < 10 ? '0' : '') + this.unavailabilityStart.day;

        const endDate = '' + this.unavailabilityEnd.year + '-'
        + (this.unavailabilityEnd.month < 10 ? '0' : '') + this.unavailabilityEnd.month + '-'
        + (this.unavailabilityEnd.day < 10 ? '0' : '') + this.unavailabilityEnd.day
        + ' 23:59';

        this.unavailabilitiesService.addUnavailability({person_id: this.person.id, starts_at: startDate, ends_at: endDate}).subscribe(
            unavailability => {
                this.unavailabilities.push(unavailability);
                this.unavailabilities.sort((a, b) => {
                    if (a.starts_at < b.starts_at) { return -1; }
                    if (a.starts_at > b.starts_at) { return 1; }
                    return 0;
                });
                this.popover.close();
            }
        );
    }

    deleteUnavailability(unavailability: Unavailability) {
        this.unavailabilitiesService.deleteUnavailability(unavailability).subscribe(
            _ => {
                this.unavailabilities = this.unavailabilities.filter(u => u.id !== unavailability.id);
            }
        );
    }

    showEvent(template, eventID) {
        this.eventsService.getEvent(eventID).subscribe(event => {
            this.selectedEvent = event;
            this.modalService.open(template, {size: 'lg'});
        });
    }

}
